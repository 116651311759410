import React from "react";

import { appTheme } from "../utils/Constants";
import { Button } from "primereact/button";
import contactimg from "../assets/img/contactus1.png"
import callsvc from "../utils/Services";
import userProfile from "../utils/Userprofile";
import { Toast as Growl} from 'primereact/toast';
import Global from "../utils/Global";

export default class Contact extends React.Component{

    constructor(props){
        super(props)
        this.state ={
            fname:'',
            phone:"",
            subject:"",
            message:"",
            
        }
    }

    submitFeedback = ()=>{

        if(this.state.fname.length < 2 || this.state.phone.length != 10 || this.state.message.length < 5 || this.state.subject.length < 3 || parseInt(this.state.phone) < 6000000000){
            this.growl.show({severity:"warn",summary:"Invalid Data!",detail:"Please enter valid data",life:6000})
            return
        }

        callsvc({orgid:userProfile.getCustObj().orgid,fstname:this.state.fname,accid:this.state.phone,subj:this.state.subject,descpt:this.state.message},'contactus',false)
        .then((res)=>{
            if(res.code=='999'){
                this.growl.show({severity:'success',summary:"Submitted",detail:res.message,life:6000})
                this.props.navProps.history.push('/')
            }
        })
    }

    render(){
        return(
            <div style={{backgroundColor:appTheme.pageBgColor,height:'100vh'}}>
            <Growl ref={(el) => this.growl = el} style={{width:"70vw"}} sticky={"true"} position="top-center" baseZIndex={1001}/>
                <div style={{display:'flex',flexDirection:'row',backgroundColor:appTheme.primaryColor,height:50,position:'sticky',top:0,left:0,right:0,color:appTheme.textColor,alignItems:'center'}}>
                    <i style={{fontSize:'1rem',marginLeft:12}} className="pi pi-arrow-left"  onClick={()=>{this.props.navProps.history.push('/')}}></i>
                    <div style={{fontSize:'1.2rem',marginBottom:3,marginLeft:'2rem'}}>Contact Us</div>
                </div>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:8}}>
                    <img src={contactimg} style={{width:100,height:100}}/>
                    <div style={{fontSize:'0.9rem',color:'#454545'}}>Your feedback is important to us,</div>
                    <div style={{fontSize:'0.9rem'}}>Please fill in below details. We'll get right back to you.</div>
                </div>
                <div style={{display:'flex',flexDirection:'column',marginTop:'0.5rem'}}>
                    <div style={{backgroundColor:"#FFFFFF",color:"#000",border:"0.2px solid grey",borderRadius:4,margin:10,padding:6}}><input type="text" maxLength={40} className="input-outline-none" placeholder="First Name" style={{border:'none',fontSize:'0.9rem',width:'100%'}} onChange={(e)=>{this.setState({fname:e.target.value})}}/></div>
                    <div style={{backgroundColor:"#FFFFFF",color:"#000",border:"0.2px solid grey",borderRadius:4,margin:'2px 10px',padding:6}}><input type="number" min={6000000000} max={9999999999} maxLength={10} className="input-outline-none" placeholder="Phone Number" style={{border:'none',fontSize:'0.9rem',width:'100%',background:'transparent'}} onChange={(e)=>{this.setState({phone:e.target.value})}}/></div>
                    <div style={{backgroundColor:"#FFFFFF",color:"#000",border:"0.2px solid grey",borderRadius:4,margin:'10px 10px',padding:6}}><input type="text" maxLength={30} className="input-outline-none" placeholder="Subject" style={{border:'none',fontSize:'0.9rem',width:'100%'}} onChange={(e)=>{this.setState({subject:e.target.value})}}/></div>
                    <div style={{backgroundColor:"#FFFFFF",color:"#000",border:"0.2px solid grey",borderRadius:4,margin:'10px 10px',padding:6}}><textarea type="textarea" columns={50} rows={5} maxLength={30} className="input-outline-none" placeholder="Your Message" style={{border:'none',fontSize:'0.9rem',width:'100%'}} onChange={(e)=>{this.setState({message:e.target.value})}}/></div>
                </div>
                <div>
                    <div style={{textAlign:'center',color:'#FFF',backgroundColor:appTheme.btnbgcolor,fontWeight:600,borderRadius:5,padding:8,alignSelf:'center',margin:"5px 10px",}} onClick={()=>{this.submitFeedback()}}>Submit</div>
                    <div style={{textAlign:'center',fontWeight:600,fontSize:'1rem',marginTop:'0.9rem'}}>OR</div>
                    <div style={{textAlign:'center',marginTop:5}}>Conact us for help @ +91 76710 99629</div>
                    <div style={{display:'flex',justifyContent:'center',marginTop:5}}><Button style={{backgroundColor:appTheme.primaryColor,border:"1px solid",borderColor:appTheme.primaryColor,padding:10}} label="Chat on Whatsapp" onClick={()=>{window.open('https://wa.me/917671099629', "_blank")}} icon="pi pi-whatsapp" iconPos="left" /></div>

                </div>
            </div>
        )
    }
}