class Global {
    static isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);;
    static AppRef = null;
    static stdObj =null;
    static homeRef = null;
    static cartRef = null;
    static navRef = null;
    static cartCount = '';
    static defaultOrg = '9GZMP1618460747';
    static notifTokenUpdated = false
    static installPromptAsked = false
    static tablist = [
        
        {id:2,label:'Students',selected:false,icon:'pi pi-user',component:'StudentList'},
        {id:3,label:'Adults',selected:false,icon:'pi pi-users',component:'AdultList'},
        {id:4,label:'Attend',selected:false,icon:'pi pi-check-circle',component:'Attendance'},
        {id:1,label:'Bookings',selected:true,icon:'pi pi-table',component:'Bookings'},
        {id:5,label:'More',selected:false,icon:'pi pi-align-justify',component:'More'},
    ]

    static setInstallPromptAskedStatus(status){
        this.installPromptAsked = status;
    }

    static setIsMobile(inp){
        this.isMobile = inp;
        console.log(this.isMobile)
    }

    static getNotifTokenUpdateStatus(){
        return this.notifTokenUpdated
    }

    static setNotifTokenUpdateStatus(val){
        this.notifTokenUpdated = val;
    }

    static setCartCount(num){
        this.cartCount = num;
        if(this.navRef){
            this.navRef.setState({cartCount:num})
        }
    }
    static setNavRef(obj){
        console.log('navref set')
        this.navRef = obj;
    }

    static setCartRef(obj){
        this.cartRef = obj;
    }

    static getCartRef(){
        return this.cartRef;
    }

    static setHomeRef(obj){
        this.homeRef = obj;
    }

    static getHomeRef(){
        return this.homeRef;
    }

    static setStdObj(obj){
        this.stdObj = obj;
    }

    static setAppRef(obj){
        
        this.AppRef = obj;
    }
    static setTabList(tablist){
        this.tablist = tablist
        this.AppRef.setState({tablist:tablist})
    }
    static showSpin(op){
        
        if(this.AppRef){
            this.AppRef.setState({showSpinner:op})
        }
    }
    
}
export default Global;