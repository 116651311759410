import React from "react";
import { Card } from "primereact/card";
import { Sidebar } from "primereact/sidebar";
import Global from "../utils/Global";
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import cart0 from '../assets/img/cart0.png';
import { appTheme } from "../utils/Constants";
import { Button } from "primereact/button";
import ProductEdit from './ProductEdit';
import callsvc from "../utils/Services";
import userProfile from "../utils/Userprofile";
import { Toast as Growl} from 'primereact/toast';
import SubmitButton from "./SubmitButton";
import {InputText} from 'primereact/inputtext';
export default class CartPage extends React.Component {
    constructor(props){
        super(props)
        let userObj = userProfile.getCustObj();
        //console.log(props.cartlist)
        this.state = {
            addnewaddr:false,
            addresses:[],
            userObj:userObj,
            comments:'',
            promoRes:{},
            showProductEdit:false,
            showsubmitDialog:false,
            selProduct:null,
            orderit:null,
            promocode: this.props.cartlist && this.props.cartlist.promocode ? this.props.cartlist.promocode : '',
            promoApplied:this.props.cartlist && this.props.cartlist.promocode && this.props.cartlist.promocode.length > 2 ? true : false,
            showmore:true,
            area:userObj && userObj.location ? userObj.location.locname:'',
            altphone:userObj && userObj.accid ? userObj.accid : '',
            lat:'',lon:'',
            delOptions: [
                {id:1, name:'Home Delivery',desc:'Delivered with in 40 minutes',selected:true},
                {id:2, name:'Store Pickup',desc:'Food will be delivered to vehicle with in 25 minutes',selected:false},
                {id:3, name:'Dine In',desc:'Food will be ready with in 25 minutes for serving',selected:false}
            ],
            pmtOptions: [
                {id:1, name:'Cash On Delivery',desc:'Pay at the time of delivery',selected:true},
                {id:2, name:'Pay Using QR At Delivery',desc:'QR code will be shown by delivery executive',selected:false},
                //{id:3, name:'Pay Using Card At Delivery',desc:'You can swipe your card at the time of delivery',selected:false},

            ],
            selectedEdit:{},
            flat:'',
            community:'',
        }
        //console.log(this.state.promocode,this.state.promoApplied)
    }

    componentDidMount(){
        //this.getGeoLocation();
        if(this.state.userObj && this.state.userObj.custid)
        this.getCustAddr();
    }

    componentDidUpdate(prevProps){
        //console.log('componentDidUpdate')
        //console.log(prevProps,this.props)
        if(prevProps.cartlist.orditlist.length != this.props.cartlist.orditlist.length){
            //console.log(prevProps)
            this.getGeoLocation();
            let promocode = this.props.cartlist && this.props.cartlist.promocode ? this.props.cartlist.promocode : '';
            let promoApplied = this.props.cartlist && this.props.cartlist.promocode && this.props.cartlist.promocode.length > 2 ? true : false;

            this.setState({promocode:promocode,promoApplied:promoApplied})
        }
    }

    delCustAddr = (it)=>{
        callsvc({custid: this.state.userObj.custid,addrid:it.addrid},'delcustaddress',true)
        .then((res)=>{
            if(res.code == '999'){
                let a = this.state.addresses.filter((item)=>item.addrid != it.addrid)
                this.setState({addresses:a})
            }
           
        })
        .catch((err)=>{

        }).finally(()=>{})

    }

    getCustAddr = ()=>{
        callsvc({custid: this.state.userObj.custid},'getcustomeraddr',true)
        .then((res)=>{
            for(let i=0;i<res.data.length;i++){
                res.data[i].selected = false;
            }
            this.setState({addresses:res.data})
            //console.log(res)

        })
        .catch((err)=>{

        }).finally(()=>{})
    }

    getGeoLocation=()=>{
        navigator.geolocation.getCurrentPosition((position)=>{
            console.log(position.coords.latitude,position.coords.longitude)
            this.setState({lat:position.coords.latitude,lon:position.coords.longitude})
        },(error)=>{
            switch(error.code) {
                case error.PERMISSION_DENIED:
                  console.log("User denied the request for Geolocation.")
                  this.setState({permissionDenied:true})
                  this.growl.show({severity: 'warn', summary: 'Please Allow Location!', detail:"We need Location to deliver",life:6000});
                  break;
                case error.POSITION_UNAVAILABLE:
                    console.log("Location information is unavailable.")
                    this.growl.show({severity: 'warn', summary: 'Location!', detail:"Please Turn on your location and Accept permission",life:6000});
                  break;
                case error.TIMEOUT:
                    console.log("The request to get user location timed out.")
                  break;
                case error.UNKNOWN_ERROR:
                    console.log("An unknown error occurred.")
                  break;
              }
        })
        
    }

    editOrderIt = (it)=>{
        let selProduct = Global.homeRef.state.productList.filter((item)=>item.id == it.prdid)
        if(selProduct.length > 0){
           selProduct = selProduct[0];
           for(let i=0;i<selProduct.proditems.length;i++){
               selProduct.proditems[i].selected = (it.prditid == selProduct.proditems[i].itemid) ? true:false;
           }
           this.setState({selProduct:selProduct,orderit:it,showProductEdit:true})
        }
   }
   clearCart = ()=>{
    callsvc({ordid:Global.homeRef.state.cartlist.ordid},'clearcart',true)
    .then((res)=>{
        if(res.code == '999'){
            Global.homeRef.setState({cartlist:{orditlist:[]},cartCount:0,promocode:'',promoApplied:false})
            Global.homeRef.getCart(userProfile.getCustObj().custid)
        }
    })
    .catch((err)=>{})
    .finally(()=>{})
}
callAddSvc = (obj)=>{
    callsvc(obj,'addtocart',false)
    .then((res)=>{
       // console.log(res);
        Global.homeRef.getCart(userProfile.getCustObj())
        //Global.homeRef.updateProdQuan(this.state.selProduct.id,res.itemcount);
    })
}

updateCartItemQuan = (op,it)=>{
    it.ordquan = op ? parseInt(it.ordquan) + 1 : parseInt(it.ordquan) - 1;
    this.callAddSvc(it);
}

   
applyPromo=()=>{
    if(this.state.promocode.length <3){
        this.growl.show({severity:'warn',summary:'Invalid!',detail:'Please enter valid coupon',life:6000})
    }
    if(!this.state.promoApplied){
        callsvc({orgid:userProfile.getCustObj().orgid,custid:userProfile.getCustObj().custid,promocode:this.state.promocode},'applypromo',false)
        .then((res)=>{
            if(res.code=='999'){
                
                this.props.cartlist.ordsubtotal = parseFloat(res.subtot).toFixed(2);
                this.props.cartlist.ordtax = parseFloat(res.tax).toFixed(2);
                this.props.cartlist.promodsc = parseFloat(res.promodsc).toFixed(2)
                this.props.cartlist.ordtotal = parseFloat(res.ordtot).toFixed(2);
                this.setState({promoRes:res,promoApplied:true});
                this.growl.show({severity:'success',summary:'Success!',detail:res.message,life:6000})
                return
            }else{
                this.growl.show({severity:'warn',summary:'Invalid!',detail:res.message,life:6000})
            }
        })
    }else{
        callsvc({ordid:this.props.cartlist.ordid},'removepromo',false)
        .then((res)=>{
            if(res.code == '999'){
                this.props.cartlist.ordsubtotal = parseFloat(res.subtot).toFixed(2);
                this.props.cartlist.ordtax = parseFloat(res.tax).toFixed(2);
                this.props.cartlist.promodsc = parseFloat(res.promodsc).toFixed(2)
                this.props.cartlist.ordtotal = parseFloat(res.ordtot).toFixed(2);
                this.setState({promoRes:res,promoApplied:false,promocode:""});
                return
            }
        })
    }
}

    addAddress = ()=>{
        let addrid = Object.keys(this.state.selectedEdit).length > 0 ? this.state.selectedEdit.addrid : '';
        callsvc ({...this.state.userObj.location,custid:this.state.userObj.custid,lat:this.state.lat,lon:this.state.lon,address:JSON.stringify({community:this.state.community,flat:this.state.flat,locname:this.state.area,addrid:addrid})},'addcustaddress',true)
        .then((res)=>{
            if(res.code == '999'){
                if(typeof res.data.address === 'string' )
                res.data.address = JSON.parse(res.data.address)
                //console.log(res.data)
                this.state.addresses.push(res.data)
            }
            
        })
        .catch((err)=>{

        }).finally(()=>{})
    }

    submitOrder = ()=>{

        let addr = this.state.addresses.filter(it=>it.selected==true);
        let delmethod = this.state.delOptions.filter(it=>it.selected==true)
        let paymethod = this.state.pmtOptions.filter(it=>it.selected==true)

        if(addr.length == 0){
            if(this.state.delOptions.filter(it=>it.selected && it.name == 'Home Delivery').length >0){
                this.growl.show({severity:'warn',summary:'Address',detail:'Please Select/Add the address',life:6000})
                return
            }else{
                addr[0] = {};
            }
        }else{
            addr[0]['address']['addrid'] = addr[0]['addrid'];
        }
        if(this.state.altphone.length!=10 || parseInt(this.state.altphone) < 6000000000){
            this.growl.show({severity:'warn',summary:'Phone',detail:'Please enter valid phone',life:6000})
            return
        }
        // if(this.state.altphone.length!=10 || parseInt(this.state.altphone) < 60000000000){
        //     this.growl.show({severity:'warn',summary:'Phone',detail:'Please enter valid phone',life:6000})
        // }
        let obj = {
            deladdr:JSON.stringify(addr[0].address),
            city:this.state.userObj.location.city,
            state:this.state.userObj.location.state,
            zipcode:this.state.userObj.location.zipcode,
            comments:this.state.comments,
            custid:this.state.userObj.custid,
            orgid:this.state.userObj.orgid,
            ordid:this.props.cartlist.ordid,
            delmethod:delmethod[0].name,
            delprice:this.props.cartlist.deliveryprice,
            locid:this.state.userObj.location.locid,
            ordtotal:this.props.cartlist.ordtotal,
            paymethod:paymethod[0].name,
            pmtstatus:'',
            pmtant:'',
            pmtid:'',
            promocode:this.state.promocode,
            source:'APP',
            walamt:0,
            lat:this.state.lat,
            lon:this.state.lon,
            promodscnt:this.props.cartlist.promodsc,
        }

        callsvc(obj,'ordersubmit',false)
        .then((res)=>{
            if(res.code =='999'){
                this.growl.show({severity:'success',summary:'Success',detail:res.message,time:6000})
                this.props.hideCartDialog()
                this.setState({showsubmitDialog:false});
                Global.homeRef.setState({cartlist:{orditlist:[]},cartCount:0})
                Global.homeRef.getCart(userProfile.getCustObj().custid)
                this.props.navProps.history.push('/orders');
            }else{
                this.growl.show({severity:'warn',summary:'Something went wrong!',detail:res.message,time:6000})
            }
        }).catch((err)=>{
            this.growl.show({severity:'warn',summary:'Something went wrong!',detail:err.message,time:6000})
        }).finally(()=>{})

    
    }

    render(){
        return( <>
    <Growl ref={(el) => this.growl = el} style={{width:"70vw"}} sticky={"true"} position="top-right" />
    <Sidebar  className="cart-screen" style={{width:'100%',height:'60%',padding:'0px !important',margin:'0px !important'}} visible={this.props.showcartDialog} showCloseIcon={false} onHide={() => this.props.hideCartDialog()} fullScreen>
    
    <div style={{height:55,width:'100%',padding:"0px !important", display:'flex',alignItems:'center',position:'fixed',top:0,left:0,right:0,zIndex:999,backgroundColor:appTheme.primaryColor,color:appTheme.textColor}}>
                <i className="pi pi-chevron-left" style={{ fontSize: '1.2rem',marginRight:12,marginLeft:12 }} onClick={()=>{this.props.hideCartDialog()}}></i>
                <div style={{fontSize:'1.2rem',marginLeft:12}}>Cart</div>
                
    </div>
    {this.props.cartlist.orditlist.length == 0 ? <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}><img src={cart0}  style={{width:'90%',height:'auto'}}/> <Button onClick={()=>{Global.homeRef.setState({showcartDialog:false})}} label="Browse Menu" icon="pi pi-check" iconPos="right" style={{backgroundColor:appTheme.btnbgcolor,marginTop:20}} /></div>:<div>  
    <div style={{display:'flex',justifyContent:'space-between',marginTop:'3rem',marginRight:12}}>
    <div style={{fontSize:'1rem',fontWeight:600,color:appTheme.btnbgcolor}} className="ml-4">Items Added</div>
    <i className="pi pi-trash"  onClick={()=>this.clearCart()}
    style={{ fontSize: '0.8rem',marginRight:12,color:appTheme.btnbgcolor,alignSelf:'center' }} ></i>
    
                

    </div>
    <Card className="navcard" style={{margin:12,borderRadius:16}}>{this.props.cartlist.orditlist.map((it)=>{
         
        return(
            <div  key={it.orditid} style={{backgroundColor:'#fff',display:"flex",flexDirection:'row',justifyContent:"space-between",marginBottom:2,padding:4}}>
                <div style={{display:'flex',flexDirection:"row",width:'100%'}}>
                
                <div>
                <div style={{fontSize:'0.9rem',fontWeight:600,marginLeft:12,alignSelf:'center'}}>{it.prdname}</div>
                <div style={{fontSize:'0.7rem',fontWeight:400,marginLeft:12,alignSelf:'center'}}>&#8377; {it.onpromo && it.promoprice? it.promoprice:it.price}</div>
                <div style={{fontSize:'0.7rem',fontWeight:400,marginLeft:12,alignSelf:'center'}}>{it.addonstr}</div>
                
                <Button iconPos="right"  onClick={()=> this.editOrderIt(it)}
                label="Edit" icon="pi pi-angle-down" className="p-button-text" style={{color:appTheme.btnbgcolor,marginLeft:12,padding:0,fontSize:'0.8rem',fontWeight:600,maxHeight:20,lineHeight:20,textOverflow:'ellipsis'}} />
                
                
               
                </div>
                <div style={{flex:1}}></div>
                <div style={{alignSelf:'center',marginRight:12}}>
                <div style={{backgroundColor:'#FFF6F7',border:`0.01em solid ${appTheme.btnbgcolor}`,borderRadius:5,display:"flex",alignItems:'center',justifyContent:'space-between',alignSelf:'center'}}>
            <i className="pi pi-minus" style={{ fontSize: '.7rem',padding:"2px 6px",color:appTheme.btnbgcolor}} onClick={()=>{this.updateCartItemQuan(false,it)}}></i>
            <div style={{color:'#444',fontWeight:600,fontSize:'.9rem',padding:"2px 6px",alignSelf:'center'}}>{it.ordquan}</div>
            <i className="pi pi-plus" style={{ fontSize: '.7rem',padding:"2px 6px",color:appTheme.btnbgcolor}} onClick={()=>{this.updateCartItemQuan(true,it)}}></i>
        </div>
        <div style={{fontSize:'0.7rem',fontWeight:600,textAlign:'right'}}>&#8377; {it.orditamt}</div>
        </div>
                </div>
            </div>
            )
    })}</Card>
    
    

    <div style={{color:appTheme.btnbgcolor,fontSize:'1rem',margin:"12px 20px",fontWeight:'600'}}>Cooking Instructions</div>
    <div style={{display:'flex',justifyContent:'center'}}>
    <InputTextarea value={this.state.comments}  style={{borderRadius:16,width:Global.isMobile ?'96%':'98%'}} placeholder="Add your cooking instructions here..." onChange={(e) => this.setState({comments:e.value})} rows={5} cols={30} />
    </div>
    <div style={{height:70}}></div>

     
    <div style={{backgroundColor:'#ffffff',heigth:'3rem',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'1rem',position:'fixed',bottom:0,left:0,right:0,padding:8}}>
        <div style={{backgroundColor:appTheme.btnbgcolor,width:'80%',textAlign:'center',padding:'8px 20px',color:'#ffffff',borderRadius:5,fontWeight:600}} onClick={()=>{this.setState({showsubmitDialog:true})}}>
            Proceed to Checkout <span>&#8377;{(parseFloat(this.props.cartlist.ordtotal) + parseFloat(this.props.cartlist.deliveryprice)).toFixed(2)}</span>
        </div>
    </div>
    
    </div>}
    </Sidebar>
    <Sidebar  className="cart-screen" style={{width:'100%',height:'60%',padding:'0px !important',margin:'0px !important'}} visible={this.state.showaddr} showCloseIcon={false} onHide={() => {this.setState({showaddr:false})}} fullScreen>
    <div style={{height:55,width:'100%',padding:"0px !important", display:'flex',alignItems:'center',position:'fixed',top:0,left:0,right:0,zIndex:999,backgroundColor:appTheme.primaryColor,color:appTheme.textColor}}>
                <i className="pi pi-chevron-left" style={{ fontSize: '1.2rem',marginRight:12,marginLeft:12 }} onClick={()=>{this.setState({showaddr:false})}}></i>
                <div style={{fontSize:'1.2rem',marginLeft:12}}>Add or Edit Address</div>
                <div style={{flex:1}}></div>
                <i className="pi pi-plus" style={{ fontSize: '1.2rem',marginRight:12,marginLeft:12 }} onClick={()=>{this.setState({addnewaddr:true})}}></i>
    </div>
    <div style={{height:20}}></div>

    
    {this.state.addnewaddr && <div className="navcard" style={{marginBottom:12,backgroundColor:'#ffffff',padding:12,boxShadow:'0 1px 3px rgb(0 0 0 / 30%)',display:'flex',flexDirection:'column'}}>
    <div style={{display:'flex'}}>
    <div style={{color:appTheme.btnbgcolor,fontWeight:500,fontSize:'1rem',marginBottom:8}}>Add New Address</div>
    <div style={{flex:1}}></div>
    <i className="pi pi-times" style={{ fontSize: '1.2rem',marginRight:8,marginLeft:8 }} onClick={()=>{this.setState({addnewaddr:false})}}></i>
    </div>

    <input value={this.state.flat} style={{padding:6,margin:4,fontSize:'0.9rem',border:'none',borderBottom:`1px solid #000`}} className="input-outline-none" placeholder="Flat/Door No.." onChange={(e)=>this.setState({flat:e.target.value})} />
    <input value={this.state.community} style={{padding:6,margin:4,fontSize:'0.9rem',border:'none',borderBottom:`1px solid #000`}} className="input-outline-none" placeholder="Community/Area.." onChange={(e)=>this.setState({community:e.target.value})} />
    <input value={this.state.area} style={{padding:6,margin:4,fontSize:'0.9rem',border:'none',borderBottom:`1px solid #000`}} className="input-outline-none" placeholder="Location.." onChange={(e)=>this.setState({area:e.target.value})} />
    
    <Button label='Add' style={{backgroundColor:appTheme.btnbgcolor}} onClick={()=>{this.addAddress()}} />
    </div> }



    
    <div>{this.state.addresses.map((it)=>{
         
         return(
             <Card className="navcard"  key={it.addrid} style={{backgroundColor:'#fff',display:"flex",flexDirection:'column',marginBottom:2,padding:4}}>
                 <div style={{display:'flex'}}>
                  <div style={{fontSize:'0.9rem',fontWeight:600,marginLeft:12}}>{it.address.flat},{it.address.community}</div>
                  <div style={{flex:1}}></div>
                  <i className="pi pi-trash" style={{ fontSize: '1.2rem',marginRight:8,marginLeft:8 }} onClick={()=>{this.delCustAddr(it)}}></i>
                 </div>
                 <div style={{fontSize:'0.7rem',fontWeight:400,marginLeft:12}}>{it.address.locname}</div>
                 
                 
                
                 
             </Card>
             )
     })}</div>

    
    

    </Sidebar>
    <Sidebar  className="cart-screen" style={{width:'100%',height:'60%',padding:'0px !important',margin:'0px !important'}} visible={this.state.showsubmitDialog} showCloseIcon={false} onHide={() => {this.setState({showsubmitDialog:false})}} fullScreen>
    <div style={{height:55,width:'100%',padding:"0px !important", display:'flex',alignItems:'center',position:'fixed',top:0,left:0,right:0,zIndex:999,backgroundColor:appTheme.primaryColor,color:appTheme.textColor}}>
                <i className="pi pi-chevron-left" style={{ fontSize: '1.2rem',marginRight:12,marginLeft:12 }} onClick={()=>{this.setState({showsubmitDialog:false})}}></i>
                <div style={{fontSize:'1.2rem',marginLeft:12}}>Order Submit</div>
    </div>
    <div style={{height:20}}></div>

    
    <div style={{fontSize:'1rem',fontWeight:600,color:appTheme.btnbgcolor}} className="ml-4 mt-4">Order Summary</div>
    <div className="navcard" style={{margin:"10px 12px",borderRadius:16,backgroundColor:'#ffffff',padding:12,boxShadow:'0 1px 3px rgb(0 0 0 / 30%)'}}>
        <div style={{margin:12,borderRadius:8,padding:11,border:'0.2px solid grey',display:'flex',flexDirection:"row",justifyContent:'space-between'}}>
            <input placeholder="Enter Coupon Code" className="input-outline-none" value={this.state.promocode} onChange={(e)=>this.setState({promocode:e.target.value})} style={{border:'none',background:'transparent',width:'100%',fontSize:'0.9rem'}} />
            <div style={{color:appTheme.btnbgcolor}} onClick={()=>{this.applyPromo()}}>{this.state.promoApplied ? 'REMOVE' : 'APPLY'}</div>
        </div>
        <div style={{display:'flex',flexDirection:'row',justifyContent:"space-between",margin:4,padding:4}}>
            <div>Item Total</div>
            <div>&#8377;{this.props.cartlist.ordsubtotal}</div>
        </div>
        {this.props.cartlist.promodsc > 0 && <div style={{display:'flex',flexDirection:'row',justifyContent:"space-between",margin:4,padding:4}}>
            <div>Coupon Applied</div>
            <div style={{color:'rgb(104, 159, 57)'}}>&#8377;{this.props.cartlist.promodsc}</div>
        </div>}
        <div style={{display:'flex',flexDirection:'row',justifyContent:"space-between",margin:4,padding:4}}>
            <div>Delivery Fee</div>
            <div>&#8377;{this.props.cartlist.deliveryprice}</div>
        </div>
        
        <div style={{display:'flex',flexDirection:'row',justifyContent:"space-between",margin:4,padding:4}}>
            <div>Govt Tax & Packing</div>
            <div>&#8377;{this.props.cartlist.ordtax}</div>
        </div>
        <hr style={{border:"0.5px dashed grey"}}/>
        <div style={{display:'flex',flexDirection:'row',justifyContent:"space-between",fontWeight:600,padding:4,marginTop:'0.9rem'}}>
            <div>Total Pay</div>
            <div>&#8377;{(parseFloat(this.props.cartlist.ordtotal) + parseFloat(this.props.cartlist.deliveryprice)).toFixed(2)}</div>
        </div>
    </div>

    <div style={{color:appTheme.btnbgcolor,fontSize:'1rem',marginLeft:20,marginBottom:10,marginTop:10,fontWeight:'600'}}>Delivery Options</div>
    <Card className="navcard" style={{marginRight:12,marginLeft:10,borderRadius:16,padding:8}}>
        
        
        {
        this.state.delOptions.map((it)=><div key={it.id} 
        onClick={() => {
            //console.log(it)
            let a = this.state.delOptions.map((item)=>{
                item.selected = it.id == item.id ? true:false;
                return item;
            })
            this.setState({delOptions:a})
        }}
        style={{display:'flex',alignItems:'center',marginBottom:8}}>
        <div>
        <div style={{fontSize:'1rem',fontWeight:600}}>{it.name}</div>
        <div style={{fontSize:'0.7rem',fontWeight:400}}>{it.desc}</div>
        

        </div>
        <div style={{flex:1}}></div>
        <RadioButton inputId={it.id} name="cod" value={it.selected}  checked={it.selected}  style={{alignSelf:'center'}}/>
        
    </div>)
    }
       
    </Card>

    {this.state.delOptions.filter(it=>it.selected && it.name =='Home Delivery').length > 0 &&<div style={{margin:12,display:'flex'}}>
        <div className="ml-2" style={{color:appTheme.btnbgcolor,fontSize:'1rem',fontWeight:'600',alignSelf:'center'}}>Delivery Address</div>
        <div style={{flex:1}}></div>
        <div   style={{color:appTheme.btnbgcolor,fontSize:'.9rem' ,fontWeight:'600',marginRight:8}} onClick={()=>{this.setState({showaddr:true})}}>Add/Edit</div>
        

    </div>}
    
    {this.state.delOptions.filter(it=>it.selected && it.name =='Home Delivery').length > 0 && (this.state.addresses.length > 0) ? <Card className="navcard" style={{marginLeft:12,marginRight:12,borderRadius:16,padding:8}}>
        {this.state.addresses.map((it)=>{
            return(<div key={it.addrid} style={{marginBottom:12,display:'flex',alignItems:'center'}} onClick={()=>{
                //console.log(it)
                let a = this.state.addresses.map((item)=>{
                    item.selected = item.addrid == it.addrid ? true:false;
                    return item;
                })
                this.setState({addresses:a})
            }}>
                <div>{it.address.flat},{it.address.community},{it.address.locname} </div>
                <div style={{flex:1}}></div>
                <RadioButton inputId={it.id} name="cod" value={it.selected}  checked={it.selected}  style={{alignSelf:'center'}}/>
        
                
                </div>)
        })}

    <span className="p-float-label mt-5">
        <InputText id="altphone" value={this.state.altphone} onChange={(e)=>this.setState({altphone:e.target.value})} />
        <label htmlFor="altphone">Alternate Phone</label>
    </span>
    </Card> : this.state.delOptions.filter(it=>it.selected && it.name =='Home Delivery').length > 0 ? <div className="navcard" style={{marginBottom:12,backgroundColor:'#ffffff',padding:12,boxShadow:'0 1px 3px rgb(0 0 0 / 30%)',display:'flex',flexDirection:'column'}}>
    <div style={{display:'flex'}}>
    </div>

    <input value={this.state.flat} style={{padding:6,margin:4,fontSize:'0.9rem',border:'none',borderBottom:`1px solid #000`}} className="input-outline-none" placeholder="Flat/Door No.." onChange={(e)=>this.setState({flat:e.target.value})} />
    <input value={this.state.community} style={{padding:6,margin:4,fontSize:'0.9rem',border:'none',borderBottom:`1px solid #000`}} className="input-outline-none" placeholder="Community/Area.." onChange={(e)=>this.setState({community:e.target.value})} />
    <input value={this.state.area} style={{padding:6,margin:4,fontSize:'0.9rem',border:'none',borderBottom:`1px solid #000`}} className="input-outline-none" placeholder="Location.." onChange={(e)=>this.setState({area:e.target.value})} />
    <input value={this.state.altphone} style={{padding:6,margin:4,fontSize:'0.9rem',border:'none',borderBottom:`1px solid #000`}} className="input-outline-none" placeholder="Alternate Number" onChange={(e)=>this.setState({altphone:e.target.value})} />
    <Button label='Add' style={{backgroundColor:appTheme.btnbgcolor}} onClick={()=>{this.addAddress()}} />
    </div> : <div></div>}

    <div style={{color:appTheme.btnbgcolor,fontSize:'1rem',marginLeft:20,marginBottom:10,marginTop:10,fontWeight:'600'}}>Payment Options</div>
    <Card className="navcard" style={{marginLeft:12,marginRight:12,borderRadius:16,padding:8}}>
        
        
        {
        this.state.pmtOptions.map((it)=><div key={it.id} 
        onClick={() => {
            //console.log(it)
            let a = this.state.pmtOptions.map((item)=>{
                item.selected = it.id == item.id ? true:false;
                return item;
            })
            this.setState({pmtOptions:a})
        }}
        style={{display:'flex',alignItems:'center',marginBottom:8}}>
        <div>
        <div style={{fontSize:'1rem',fontWeight:600}}>{it.name}</div>
        <div style={{fontSize:'0.7rem',fontWeight:400}}>{it.desc}</div>
        

        </div>
        <div style={{flex:1}}></div>
        <RadioButton inputId={it.id} name="cod" value={it.selected}  checked={it.selected}  style={{alignSelf:'center'}}/>
        
    </div>)
    }
       
    </Card>
    
    <div style={{height:100}}></div>
    {!Global.homeRef.state.closeStatus && <div style={{backgroundColor:'#ffffff',heigth:'3rem',display:'flex',justifyContent:'center',alignItems:'center',position:'fixed',bottom:0,left:0,right:0,padding:8}}>
        <div style={{backgroundColor:appTheme.btnbgcolor,width:'80%',textAlign:'center',padding:'8px 20px',color:'#ffffff',borderRadius:5,fontWeight:600}} onClick={()=>{this.submitOrder()}}>
            Submit Order <span>&#8377;{(parseFloat(this.props.cartlist.ordtotal) + parseFloat(this.props.cartlist.deliveryprice)).toFixed(2)}</span>
        </div>
    </div>}



    

    
    

    </Sidebar>
    {this.state.showProductEdit && <ProductEdit  orderit={this.state.orderit} selProduct={this.state.selProduct} hideSidebarDialog={()=>{this.setState({showProductEdit:false})}}/>}        
       </> )// end render
    }
}