import React from "react";
import { appTheme } from "../utils/Constants";
import callsvc from "../utils/Services";
import userProfile from "../utils/Userprofile";

export default class About extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            aboutlist : []
        }
    }

    componentDidMount(){
        this.getAboutList();
    }

    getAboutList=()=>{
        callsvc({orgid:userProfile.getCustObj().orgid},'aboutus',false)
        .then((res)=>{
            if(res.code=='999'){
                this.setState({aboutlist:res.data});
            }
        })
    }

    render(){
        let aboutlist = this.state.aboutlist;
        return(
            <div style={{backgroundColor:appTheme.pageBgColor,height:'100vh'}}>
                <div style={{display:'flex',flexDirection:'row',backgroundColor:appTheme.primaryColor,height:50,position:'sticky',top:0,left:0,right:0,color:appTheme.textColor,alignItems:'center'}}>
                    <i style={{fontSize:'1rem',marginLeft:12}} className="pi pi-arrow-left"  onClick={()=>{this.props.navProps.history.push('/')}}></i>
                    <div style={{fontSize:'1.2rem',marginBottom:3,marginLeft:'2rem'}}>About Us</div>
                </div>
                <div style={{height:50}}></div>
                {aboutlist.map((it)=>{
                    if(it.type=='image'){
                        return(
                            <img key={it.listid} src={it.media} style={JSON.parse(it.style)} />
                        )
                    }else if(it.type=='heading'){
                        return(
                        <h2  key={it.listid} style={JSON.parse(it.style)}>{it.description}</h2>
                        )
                    }else if(it.type=='subheading'){
                        return(
                        <h3  key={it.listid} style={{...JSON.parse(it.style),...{margin:5}}}>{it.description}</h3>
                        )
                    }else if(it.type=="paragraph"){
                        return(
                            <p  key={it.listid} style={{...JSON.parse(it.style),...{margin:6}}}>{it.description}</p>
                        )
                    }
                })}

            </div>
        )
    }
}