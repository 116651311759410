import React from "react";
import Global from "../utils/Global";
import userProfile from "../utils/Userprofile";
import callsvc from "../utils/Services";
import Cartbtn from "../components/Cartbtn";

import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Button } from "primereact/button";
import { Sidebar } from 'primereact/sidebar';        
import { InputText } from "primereact/inputtext";
import { Toast as Growl} from 'primereact/toast';
import { appTheme } from "../utils/Constants";

export default class Cart extends React.Component{
    constructor(props){
        super(props)
       
    }

    componentDidMount(){
       // this.getCart();
        //this.validateCart();
    }


    validateCart=()=>{
        console.log(this.props.cartlist)
        if(Object.keys(this.props.cartlist).length === 0){
            //console.log('gf')
            this.props.navProps.history.push('/')
        } 
    }

    getCart=()=>{
        //console.log(userProfile.getCustObj())
        if(userProfile.getCustObj() && userProfile.getCustObj().custid){
            let custObj = userProfile.getCustObj()
            //console.log(custObj)
            callsvc({orgid:Global.defaultOrg,custid:custObj.custid},'getnewcart',false)
            .then((res)=>{
                if(res.code=='999'){
                    console.log(res)
                   for(let i=0;i<res.orditlist.length;i++){
                    res.orditlist[i]['itemid'] = res.orditlist[i]['prditid'];
                   }
                   if(Global.getCartRef()){
                   // Global.getCartRef().setState({updated:true})
                   }
                    this.setState({cartlist:res,cartcount:res.cartcount})
                }else{
                    this.setState({cartlist:res,cartcount:res.cartcount})
                }
            }).catch((err)=>{
    
            }).finally(()=>{})
        }
    }

    navigateHome=()=>{
        this.props.navProps.history.push('/')
    }

    getGeoLocation=()=>{
        navigator.geolocation.getCurrentPosition((position)=>{
            console.log(position.coords.latitude,position.coords.longitude)
            this.setState({latitude:position.coords.latitude,longitude:position.coords.longitude})
        },(error)=>{
            switch(error.code) {
                case error.PERMISSION_DENIED:
                  console.log("User denied the request for Geolocation.")
                  this.setState({permissionDenied:true})
                  break;
                case error.POSITION_UNAVAILABLE:
                    console.log("Location information is unavailable.")
                  break;
                case error.TIMEOUT:
                    console.log("The request to get user location timed out.")
                  break;
                case error.UNKNOWN_ERROR:
                    console.log("An unknown error occurred.")
                  break;
              }
        })
        
    }

    orderSubmit=()=>{
        if(this.state.locality.length < 2 || this.state.flat.length < 2 || this.state.community.length < 2){
            this.growl.show({severity: 'warn', summary: 'Invalid Address', detail:"Please enter your address",life:6000});
            return

        }
        callsvc({orgid:Global.defaultOrg,custid:userProfile.getCustObj().custid,community:this.state.community,area:this.state.area,delmethod:this.state.delmethod,delprice:this.state.delprice,ordtotal:this.state.ordtotal,comments:this.state.comments,locid:this.state.locid},'ordersubmit',false)
        .then((res)=>{
            if(res.code=='999'){
                this.growl.show({severity: 'info', summary: 'Success', detail:"Order Submitted Successfully!",life:6000});
                this.props.navProps.history.push('/');
            }
        })
    }
            
    

    render(){
        let cartlist = this.props.cartlist;
        console.log('cartlist')

        return(
            <>  
            <div style={{height:55,width:'100%',padding:"0px !important", display:'flex',backgroundColor:appTheme.primaryColor,color:appTheme.textColor,alignItems:'center',position:'sticky',top:0,left:0,right:0,zIndex:999}}>
                <i className="pi pi-search" style={{ fontSize: '1.5rem',marginRight:12,marginLeft:12 }} onClick={()=>{}}></i>
                <div style={{fontSize:24,marginLeft:12}}>Your Order Items</div>
                <div style={{flex:1}}></div>
               
            

            </div>
                             
            </>

        )

    }
}