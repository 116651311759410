import React from "react";
import {Button} from 'primereact/button'
import userProfile from "../utils/Userprofile";
import Global from "../utils/Global";
import callsvc from "../utils/Services";
import { appTheme } from "../utils/Constants";
import { Sidebar } from "primereact/sidebar";
import { Card } from "primereact/card";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import ProductEdit from "./ProductEdit";
export default class Cartbtn extends React.Component{
    constructor(props){
        super(props);
        //console.log(props.item)
        this.state = {
            custObj : userProfile.getCustObj(),
            selProduct:Object.assign({},this.props.selProduct),
            showCartItems:false,
            sideBarDialog:false,
            showNewProduct:false,
            showNewProductBtn:false,
            prdquan:0,
            addOnPrice:0,
            defQuan:1,
            addonids:[],
            orderit:null
        }
    }

    componentDidUpdate(curr){
         //console.log(curr);
         if(curr.selProduct.prdquan != this.state.selProduct.prdquan){
          this.state.selProduct.prdquan = curr.selProduct.prdquan;
          this.setState({selProduct:this.state.selProduct, sideBarDialog: curr.selProduct.prdquan == 0 ? false:this.state.sideBarDialog})
         }
      }
    changeCartItems=(op)=>{
        //let custObj = userProfile.getCustObj();
        //Global.homeRef.getCart(custObj);

        if(op){
            let cartItems = Global.homeRef.state.cartlist.orditlist.filter((it)=>it.prdid == this.state.selProduct.id)
            this.setState({sideBarDialog:true,showCartItems:cartItems.length > 0 ? true : false,showNewProductBtn:true,orderit:null})
        }else{
            let item = Global.homeRef.state.cartlist.orditlist.filter((it)=>it.prdid == this.state.selProduct.id)
            if(item.length == 1){
                //console.log(item[0])
                item[0].ordquan = parseInt(item[0].ordquan) - 1;
                this.callAddSvc(item[0])
            }else{
                let cartItems = Global.homeRef.state.cartlist.orditlist.filter((it)=>it.prdid == this.state.selProduct.id)
                this.setState({sideBarDialog:true,showCartItems:cartItems.length > 0 ? true : false,showNewProductBtn:false,orderit:null})
            }
        }
        //this.setState({showCartDialog:true,showCartItems:true});
    }

    
    

    

    updateQuan=(type)=>{
        
        if(type=='add'){
            //this.calPrice(this.state.defQuan+1)
            this.setState({defQuan:this.state.defQuan+1})
        }else{
            if(this.state.defQuan <=1){
                this.setState({defQuan:1,sideBarDialog:false})
                
                return
            }
            //this.calPrice(this.state.defQuan-1)
            this.setState({defQuan:this.state.defQuan-1})

        }

    }

    callAddSvc = (obj)=>{
        callsvc(obj,'addtocart',false)
        .then((res)=>{
            console.log(res);
            Global.homeRef.getCart(userProfile.getCustObj())
            //Global.homeRef.updateProdQuan(this.state.selProduct.id,res.itemcount);
        })
    }

    
    sendToCart = ()=>{
        
        let selected = this.state.selProduct.proditems.filter((it)=>it.selected)
        selected = selected[0];
        //console.log(selected.addons)

        let addonArr = [];
        for(let i=0;i<selected.addons.length;i++){
            for(let j=0;j<selected.addons[i].data.length;j++){
                if(selected.addons[i].data[j].selected){
                    addonArr.push(selected.addons[i].data[j])
                }
            }
        }
        
        //selected.addons.filter((it)=>it.selected);
        let sendObj = {orditid:'',hsn:this.state.selProduct.hsn, prdid:this.state.selProduct.id, prdname:this.state.selProduct.prdname,prdimg:this.state.selProduct.prdimg ,price:selected.price, size:selected.size, onpromo:selected.promo,promoprice:selected.promoprice,
        ordquan:this.state.defQuan,prditid:selected.itemid,addons:JSON.stringify(addonArr),
        totprice:((parseFloat((selected.promo && selected.promoprice)? selected.promoprice:selected.price) + parseFloat(this.state.addOnPrice)) * this.state.defQuan).toFixed(2),
        custid:userProfile.getCustObj().custid,orgid:Global.defaultOrg
        }
        this.callAddSvc(sendObj)
        
        //console.log(sendObj);

        this.setState({sideBarDialog:false})
       
    }

    updateCartItemQuan = (op,it)=>{
        it.ordquan = op ? parseInt(it.ordquan) + 1 : parseInt(it.ordquan) - 1;
        this.callAddSvc(it);
        
        
    }

    
    addToCart = ()=>{
        //console.log("Global Prompt Status",Global.installPromptAsked)
        if(Global.homeRef.state.installPrompt && !Global.installPromptAsked){
            Global.homeRef.state.installPrompt.prompt();
            Global.homeRef.state.installPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                  console.log('User accepted the install prompt');
                } else {
                  console.log('User dismissed the install prompt');
                }
                Global.setInstallPromptAskedStatus(true);
              })
        }else{
            //console.log(Global.homeRef.state.installPrompt)
        }

        let custObj = userProfile.getCustObj()
        if(custObj && custObj.custid){
            for(let i=0; i<this.state.selProduct.proditems.length;i++){
                for(let j=0; j<this.state.selProduct.proditems[i].addons.length;j++){
                    for(let k=0; k<this.state.selProduct.proditems[i].addons[j].data.length;k++){
                        this.state.selProduct.proditems[i].addons[j].data[k].selected = false;
                    }
                }
            }
            this.setState({selProduct: this.state.selProduct,showNewProduct:true,orderit:null})
        }else{
            Global.homeRef.setState({loginDialog:true})
            console.log('cust not logged In')
        }
        
    }



    render(){
        
        //let selected = this.state.selProduct.proditems.filter((it)=>it.selected)
        //selected = selected[0];
       
        return(<>
        {this.props.type == 'card' ? 
        
        this.state.selProduct.prdquan > 0 ? <div style={{backgroundColor:appTheme.btnbgcolor,borderColor:appTheme.btnbgcolor,borderRadius:5,display:"flex",alignItems:'center',justifyContent:'space-between',padding:"8px 0px",marginLeft:8,alignSelf:'center'}}>
                         <i className="pi pi-minus" style={{ fontSize: '.76rem',padding:"6px 16px",color:'#fff'}} onClick={()=>{this.changeCartItems(false)}}></i>
                         <div style={{color:'#fff',fontWeight:600,fontSize:'1.2rem',padding:"1px 0px"}}>{this.state.selProduct.prdquan}</div>
                         <i className="pi pi-plus" style={{ fontSize: '.8rem',padding:"6px 16px",color:'#fff'}} onClick={()=>{this.changeCartItems(true)}}></i>
                     </div> :<div style={{backgroundColor:appTheme.btnbgcolor,borderColor:appTheme.btnbgcolor,padding:"8px 16px",boxShadow:'0px 5px 10px 0px rgba(0, 0, 0, 0.5)',  alignSelf:'center',borderRadius:5,marginLeft:4,color:'#fff'}} onClick={()=>{this.addToCart()}}>Add</div>
            :
             this.state.selProduct.prdquan == 0? <div style={{backgroundColor:appTheme.btnbgcolor,borderColor:appTheme.btnbgcolor,padding:"6px 16px",boxShadow:'0px 5px 10px 0px rgba(0, 0, 0, 0.5)',  alignSelf:'center',borderRadius:5,marginRight:12,color:'#fff'}} onClick={()=>{this.addToCart()}}>Add</div> :
                <div style={{alignSelf:'center',marginRight:12}}>
                        <div style={{backgroundColor:'#FFF6F7',border:`0.01em solid ${appTheme.btnbgcolor}`,borderRadius:5,display:"flex",alignItems:'center',justifyContent:'space-between',alignSelf:'center'}}>
                    <i className="pi pi-minus" style={{ fontSize: '.7rem',padding:"6px 6px",color:appTheme.btnbgcolor}} onClick={()=>{this.changeCartItems(false)}}></i>
                    <div style={{color:'#444',fontWeight:600,fontSize:'.9rem',padding:"4px 6px",alignSelf:'center'}}>{this.state.selProduct.prdquan}</div>
                    <i className="pi pi-plus" style={{ fontSize: '.7rem',padding:"6px 6px",color:appTheme.btnbgcolor}} onClick={()=>{this.changeCartItems(true)}}></i>
                </div>
                </div>}

                {this.state.selProduct.prdquan > 0 &&<Sidebar className="loc-side-bar" visible={this.state.sideBarDialog} showCloseIcon={false} onHide={() => {this.setState({sideBarDialog:false})}} style={Global.isMobile ? {backgroundColor:'transparent',height:'100vh',width:'100vh',display:'flex'}: {backgroundColor:'transparent',height:'100vh',width:'90vw'}} position={'bottom'}>
                    <div style={{height:'15%',position:'relative'}}>
                        <i className="pi pi-times" style={{ fontSize: '1.2rem',position:'absolute',bottom:0,left:'50%',color:'#fff',backgroundColor:'#000',padding:12,transform:'translate(-50%, -50%)' }} onClick={()=>{this.setState({sideBarDialog:false})}}></i>
                    </div>
                    <div style={{backgroundColor:'#FAFAFA',height:'85%',width:'100%',borderRadius:20,overflow:'scroll',position:'relative'}}>
                    <Card style={{backgroundColor:'#fff',borderRadius:10,padding:'0px !important'}} className="navcard">
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <img src={this.state.selProduct.prdimg} style={{width:40,height:40,marginLeft:12}}/>
                            <div style={{fontSize:'1.2rem',fontWeight:'600',marginLeft:12,alignSelf:'center'}}>{this.state.selProduct.prdname}</div>
                        </div>
                    </Card>
                    <div style={{height:10}}></div>
           
               {this.state.showCartItems && this.state.selProduct.prdquan > 0  && <Card  style={{backgroundColor:'#fff',borderRadius:10,padding:8,margin:8}} className="navcard">
               <div style={{fontSize:'1.3rem',fontWeight:600,paddingBottom:12,borderBottom:"0.2px solid #d3d3d3"}}>Items added</div>
               
               {Global.homeRef.state.cartlist.orditlist.map((it)=>{
                   
                   if(it.prdid == this.state.selProduct.id )
                   return(
                    <div  key={it.orditid} style={{backgroundColor:'#fff',display:"flex",flexDirection:'row',justifyContent:"space-between",marginBottom:2,padding:4}}>
                        <div style={{display:'flex',flexDirection:"row",width:'100%'}}>
                        
                        <div>
                        <div style={{fontSize:'0.9rem',fontWeight:600,marginLeft:12,alignSelf:'center'}}>{it.prdname}</div>
                        <div style={{fontSize:'0.7rem',fontWeight:400,marginLeft:12,alignSelf:'center'}}>&#8377; {it.onpromo && it.promoprice? it.promoprice:it.price}</div>
                        <div style={{fontSize:'0.7rem',fontWeight:400,marginLeft:12,alignSelf:'center'}}>{it.addonstr}</div>
                        
                        <Button iconPos="right"  onClick={()=>{
                                                        this.setState({orderit:it,showNewProduct:true})
                        }}
                        label="Edit" icon="pi pi-angle-down" className="p-button-text" style={{color:appTheme.btnbgcolor,marginLeft:12,padding:0,fontSize:'0.8rem',fontWeight:600,maxHeight:20,lineHeight:20,textOverflow:'ellipsis'}} />
                        
                        
                       
                        </div>
                        <div style={{flex:1}}></div>
                        <div style={{alignSelf:'center',marginRight:12}}>
                        <div style={{backgroundColor:'#FFF6F7',border:`0.01em solid ${appTheme.btnbgcolor}`,borderRadius:5,display:"flex",alignItems:'center',justifyContent:'space-between',alignSelf:'center'}}>
                    <i className="pi pi-minus" style={{ fontSize: '.7rem',padding:"2px 6px",color:appTheme.btnbgcolor}} onClick={()=>{this.updateCartItemQuan(false,it)}}></i>
                    <div style={{color:'#444',fontWeight:600,fontSize:'.9rem',padding:"2px 6px",alignSelf:'center'}}>{it.ordquan}</div>
                    <i className="pi pi-plus" style={{ fontSize: '.7rem',padding:"2px 6px",color:appTheme.btnbgcolor}} onClick={()=>{this.updateCartItemQuan(true,it)}}></i>
                </div>
                <div style={{fontSize:'0.7rem',fontWeight:600,textAlign:'right'}}>&#8377; {it.orditamt}</div>
                </div>
                        </div>
                    </div>
                    )
               })}
               
           </Card>}

           {this.state.showNewProductBtn && <div 

           style={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
            <div style={{textAlign:'center',marginBottom:8}}>OR</div>
            <Button label="Add New Customization"
            style={{backgroundColor:appTheme.btnbgcolor,borderColor:appTheme.btnbgcolor,color:'#fff'}}
            onClick={()=>{ this.state.addonids=[];this.setState({sideBarDialog:false,showNewProduct:true,orderit:null})}} /></div>}
		     </div>
   </Sidebar>}
     
     {this.state.showNewProduct && <ProductEdit  orderit={this.state.orderit} selProduct={this.state.selProduct} hideSidebarDialog={()=>{this.setState({showNewProduct:false})}}/>}        
        </>)
    }
}