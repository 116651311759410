import React from "react";
import { appTheme } from "../utils/Constants";

export default class SubmitButton extends React.Component{

    constructor(props){
        super(props)
    }

    render(){

        return (<div style={{display:'flex',flexDirection:'column',position:'fixed',bottom:0,left:0,right:0,zIndex:99,padding:'0.6rem 0.3rem',background:'#4a4a4a',color:'#fff'}}>
            <div style={{display:'flex',alignItems:'center',padding:8}}>
                <i className="pi pi-map-marker" style={{marginRight:12}}></i>
                <div> A 205, Blossom Heights,Kolluru</div>
                <div style={{flex:1}}></div>
                <i className="pi pi-map-marker" style={{marginRight:12}}></i>
            </div>
            
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <div style={{color:'#fff',marginLeft:7}}>Total Rs 1296</div>
            <div style={{flex:1}}></div>
            <div>
                <button  style={{border:'1px solid',borderColor:appTheme.btnbgcolor,color:'#fff',backgroundColor:appTheme.btnbgcolor,position:'relative',overflow:'hidden',padding:9,fontSize:'0.75em',borderRadius:'3px',outline:'none',textTransform:'upppercase',marginRight:7,fontWeight:'bold',boxSizing:'border-box'}}
                onClick={()=>this.props.cartOpen()}>Submit Order</button>
            </div>
        </div>
    </div>)

        
    }
}