import React from "react";
import Global from "../utils/Global";
import { appTheme } from "../utils/Constants";
import Sidemenu from "./SideMenu";


export default class Navbar extends React.Component{

    constructor(props){
        super(props)
        Global.setNavRef(this);
        this.state = {
            cartCount:0
        }
    }
    
    componentDidMount(){
    
    }
    
    render(){
        return(

            <>
            

            <div style={{height:55,width:'100%', display:'flex',backgroundColor:appTheme.primaryColor,color:appTheme.textColor,alignItems:'center',position:'sticky',top:0,left:0,right:0,zIndex:999}}>
                <Sidemenu navProps={this.props.navProps} />
                <div style={{fontSize:24,marginLeft:12}}>Pure Pizza</div>
                <div style={{flex:1}}></div>
               
            

            </div>

            </>


        )
    }


}