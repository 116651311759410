import React from "react";
import ImageComponent from "./ImageComponent";
import ProductCard from './ProductCard';



export default class Catwithprods extends React.Component{
    constructor(props){
        super(props);
        //console.log(props);
    }

    render(){

        return(<div>
            {
                this.props.data.items.map((it,index)=>{
                    if(it.products.length%2 !=0){
                        it.products.push({id:index,prdname:'empty',proditems:[]})
                    }
                    return(
                    <div key={it.pdlid}>
                    <ImageComponent imgurl={it.banner} height={150} width="100%" />
                    <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',marginTop:8}}>{
                    it.products.map((p)=>{
                        return(<ProductCard key={p.id} data={p}/>)
                    })
                    }</div>
                    
                    </div>
                    )
                })

            }

        </div>)
    }
}