import React from "react";
import ImageComponent from "./ImageComponent";
import {Card} from 'primereact/card'
import Cartbtn from "./Cartbtn";
import { Dropdown } from 'primereact/dropdown';
import { appTheme } from "../utils/Constants";
import Global from "../utils/Global";
import {Button} from 'primereact/button';
import { Sidebar } from "primereact/sidebar";
import { RadioButton } from "primereact/radiobutton";

import { Checkbox } from 'primereact/checkbox';
import userProfile from "../utils/Userprofile";
import callsvc from "../utils/Services";
import ProductModal from './ProductModal';

        
export default class ProductCard extends React.Component{

    constructor(props){
        super(props)
        //console.log(props.data.proditems ? props.data.proditems.length:props.data)
        this.state = {
            showCartDialog:false,
            selProduct: Object.assign({},this.props.data),
            defQuan:1,
            selected : Object.assign({},this.props.data).proditems.filter((it)=>it.selected),
            addOnPrice:0,
            showCartItems:false,
            sideBarDialog:false
            
        }
        
        
    }



    componentDidUpdate(curr){
      // console.log(curr);
       if(curr.data.prdquan != this.state.selProduct.prdquan){
        this.state.selProduct.prdquan = curr.data.prdquan;
        this.setState({selProduct:this.state.selProduct})
       }
    }


    addAddon = (it,addonid,type)=>{
        for(let k=0;k<this.state.selProduct.proditems.length;k++){
            if(this.state.selProduct.proditems[k].itemid == it.itemid){
                //it.seltotal = parseFloat(it.price) * this.state.defQuan;
                this.state.addOnPrice = 0;
                for(let i=0;i<it.addons.length;i++){
                    for(let j=0;j<it.addons[i].data.length;j++){
                        if(type=='radio' && it.addons[i].btntype == 'radio'){
                            if((it.addons[i].data[j].addonid == addonid) ){
                                it.addons[i].data[j].selected = 
                                (it.addons[i].data[j].selected) ? false :
                                true
                            }else {
                                it.addons[i].data[j].selected = false
                           
                            }
                        }else{
                            if(it.addons[i].data[j].addonid == addonid){
                                it.addons[i].data[j].selected = 
                                (it.addons[i].data[j].selected) ? false :
                                true
                            }
    
                        }
                        if(it.addons[i].data[j].selected)
                        this.state.addOnPrice = (parseFloat(this.state.addOnPrice) + parseFloat(it.addons[i].data[j].price)).toFixed(2);
                    }
                }
            }
            
        }
        
        this.setState({selProduct:this.state.selProduct,addOnPrice:this.state.addOnPrice,defQuan:1});
    }

    // calPrice=(qty,type)=>{
    //     for(let i=0;i<this.state.selProduct.proditems.length;i++){
    //         console.log(this.state.selected)
    //         if(this.state.selProduct.proditems[i].itemid==this.state.selected[0].itemid){
    //             if(type=='add')
    //             this.state.selProduct.proditems[i].seltotal = (parseFloat(this.state.selProduct.proditems[i].seltotal) * parseInt(qty)).toFixed(2);
    //         }
    //     }
    //     this.setState({selProduct:this.state.selProduct})
    // }

    updateQuan=(type)=>{
        
        if(type=='add'){
            //this.calPrice(this.state.defQuan+1)
            this.setState({defQuan:this.state.defQuan+1})
        }else{
            if(this.state.defQuan <=1){
                this.setState({defQuan:1,showCartDialog:false})
                return
            }
            //this.calPrice(this.state.defQuan-1)
            this.setState({defQuan:this.state.defQuan-1})

        }

    }

    sendToCart = ()=>{
        
        let selected = this.state.selProduct.proditems.filter((it)=>it.selected)
        selected = selected[0];
        //console.log(selected.addons)

        let addonArr = [];
        for(let i=0;i<selected.addons.length;i++){
            for(let j=0;j<selected.addons[i].data.length;j++){
                if(selected.addons[i].data[j].selected){
                    addonArr.push(selected.addons[i].data[j])
                }
            }
        }
        
        //selected.addons.filter((it)=>it.selected);
        let sendObj = {orditid:'',hsn:this.state.selProduct.hsn, prdid:this.state.selProduct.id, prdname:this.state.selProduct.prdname,prdimg:this.state.selProduct.prdimg ,price:selected.price, size:selected.size, onpromo:selected.promo,promoprice:selected.promoprice,
        ordquan:this.state.defQuan,prditid:selected.itemid,addons:JSON.stringify(addonArr),
        totprice:((parseFloat((selected.promo && selected.promoprice)? selected.promoprice:selected.price) + parseFloat(this.state.addOnPrice)) * this.state.defQuan).toFixed(2),
        custid:userProfile.getCustObj().custid,orgid:Global.defaultOrg
        }
        this.callAddSvc(sendObj)
        
        //console.log(sendObj);

        this.setState({showCartDialog:false})
       
    }

    callAddSvc = (obj)=>{
        callsvc(obj,'addtocart',false)
        .then((res)=>{
            console.log(res);
            Global.homeRef.getCart(userProfile.getCustObj())
            //Global.homeRef.updateProdQuan(this.state.selProduct.id,res.itemcount);
        })
    }

    updateCartItemQuan = (op,it)=>{
        it.ordquan = op ? parseInt(it.ordquan) + 1 : parseInt(it.ordquan) - 1;
        this.callAddSvc(it);
    }

    addToCart = ()=>{
        console.log("Global Prompt Status",Global.installPromptAsked)
        if(Global.homeRef.state.installPrompt && !Global.installPromptAsked){
            Global.homeRef.state.installPrompt.prompt();
            Global.homeRef.state.installPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                  console.log('User accepted the install prompt');
                } else {
                  console.log('User dismissed the install prompt');
                }
              })
              Global.setInstallPromptAskedStatus(true);
        }else{
            console.log(Global.homeRef.state.installPrompt)
        }

        let custObj = userProfile.getCustObj()
        if(custObj && custObj.custid){
            for(let i=0; i<this.state.selProduct.proditems.length;i++){
                for(let j=0; j<this.state.selProduct.proditems[i].addons.length;j++){
                    for(let k=0; k<this.state.selProduct.proditems[i].addons[j].data.length;k++){
                        this.state.selProduct.proditems[i].addons[j].data[k].selected = false;
                    }
                }
            }
            this.setState({selProduct: this.state.selProduct,sideBarDialog:true})
        }else{
            Global.homeRef.setState({loginDialog:true})
            console.log('cust not logged In')
        }
        
    }
    changeCartItems=(op)=>{
        //let custObj = userProfile.getCustObj();
        //Global.homeRef.getCart(custObj);

        if(op){
            this.setState({sideBarDialog:true,showCartItems:true})
        }else{
            let item = Global.homeRef.state.cartlist.orditlist.filter((it)=>it.prdid == this.state.selProduct.id)
            if(item.length == 1){
                //console.log(item[0])
                item[0].ordquan = parseInt(item[0].ordquan) - 1;
                this.callAddSvc(item[0])
            }else{
                this.setState({sideBarDialog:true,showCartItems:true})
            }
        }
        //this.setState({showCartDialog:true,showCartItems:true});
    }

    getCard = (op,selected)=>{
        if(op == 'list'){
            return(<Card className="navcard" style={{marginTop:2,backgroundColor:'#fff',borderRadius:10}}>
                <div style={{display:'flex',flexDirection:'row'}}>
               <img src={this.state.selProduct.prdimg} style={{width:60,height:40,alignSelf:'center'}}/>
               <div style={{display:'flex',flexDirection:'column',marginLeft:8}}>
                    <div style={{fontSize:'1rem',fontWeight:600}}>{this.state.selProduct.prdname} </div>
                    <div style={{fontSize:'.8rem',overflow:'hidden',height:'48px',lineHeight:'16px',fontWeight:380,fontFamily:'candara'}}>{this.state.selProduct.description} </div>
                 
              <div style={{display:'flex',marginTop:8}}>  
            {selected.promo ? <div>
             <span style={{
             color:'#333',
             fontSize:'1.2 rem',
             fontWeight:'700',marginRight:5}}>&#8377;{selected.promoprice}</span>
              <s style={{fontSize:"0.8rem"}}>&#8377;{selected.price}</s>
            </div>:
            <div style={{
             color:'#333',
             fontSize:'1.2 rem',
             fontWeight:'700',}}>&#8377;{selected.price}</div>}
                 
                <div style={{flex:1}}></div>
                <Cartbtn selProduct={this.state.selProduct} type="list" />
                </div>
                </div>
                </div>  
            </Card>)
        }else{
            return(<Card className="col-12 md:col-3" style={{maxHeight: 420,position:'relative',padding:8,marginTop:8,backgroundColor:'#f9f9f9',alignSelf:'center',borderBottom:'1px solid #f9f9f9'}}>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',position:'relative'}}>
            <img src={this.state.selProduct.prdimg} style={{width:'100%',height:160}}/>
            </div>
            {selected.promo ? <div>
             <span style={{
             color:'#333',
             fontSize:'1.2 rem',
             fontWeight:'700',marginRight:5}}>&#8377;{selected.promoprice}</span>
              <s style={{fontSize:"0.8rem"}}>&#8377;{selected.price}</s>
            </div>:
            <div style={{
             color:'#333',
             fontSize:'1.2 rem',
             fontWeight:'700',}}>&#8377;{selected.price}</div>}
     
            <div style={{marginTop:8,fontSize:'1.2rem',height:'37px',lineHeight:'20px',fontWeight:600}}>{this.state.selProduct.prdname} </div>
            <div style={{marginTop:0,fontSize:'1rem',overflow:'hidden',height:'80px',lineHeight:'20px',fontWeight:370,fontFamily:"candara"}}>{this.state.selProduct.description} </div>
            
            
            <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',alignSelf:'center',width:'100%',flexWrap:'wrap',marginTop:12}}>
                <Cartbtn selProduct={this.state.selProduct} type="card" />
             </div>
            
                
            </Card>)
        }
    }

    setSelectedSize = (itemid)=> {
        for(let i=0;i<this.state.selProduct.proditems.length;i++){
            this.state.selProduct.proditems[i].selected = (this.state.selProduct.proditems[i].itemid == itemid) ? true : false; 
        }
        this.setState({selProduct:this.state.selProduct})
    }


    render(){
        
        //console.log('productcard  re-redered')
        
        let selected = this.state.selProduct.proditems.filter((it)=>it.selected)
        selected = selected[0];
        //console.log(selected)
       return(
        <> {this.props.type && this.props.type == 'list' ? 
        Global.isMobile ? this.getCard('list',selected) : this.getCard('card',selected):this.getCard('card',selected)}
       
    {this.state.sideBarDialog && <ProductModal 
    selProduct = {this.state.selProduct}
    sideBarDialog = {this.state.sideBarDialog} 
    hideSidebarDialog={()=>{this.setState({sideBarDialog:false})}}
    showCartItems = {this.state.showCartItems}
    
    />}


       
       </>
       
       )
    }

}