import React from "react";
import { Carousel } from 'primereact/carousel';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Autoplay, Pagination, Navigation } from "swiper";
import Global from "../utils/Global"

export default class Slider extends React.Component {

    constructor(props){
        super(props)
        //console.log(props.data.height)
        
    }

    componentDidMount(){
    }

    scrollTemplate = (it)=>{
          return(<img key={it.id} src={it.imgurl} style={{width:'100%',height:parseInt(this.props.data.height)}} />)
    }

    render(){

        let items = this.props.data.items;
        //console.log(items)
        return(
            <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
      {items.map((it)=>{
        return(
        <SwiperSlide key={it.id}>
        {Global.isMobile ? <img key={it.id} src={it.imgurl} style={{width:'100%',height:200}} /> : <img key={it.id} src={it.imgurl} style={{width:'75%',height:200,marginLeft:'10rem'}} />}
        
        </SwiperSlide>
        )
      })}
        
        
      </Swiper>
            )
    }


}