import React from "react";
import { Sidebar } from "primereact/sidebar";
import { Card } from "primereact/card";
import Global from "../utils/Global";
import { appTheme } from "../utils/Constants";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import userProfile from "../utils/Userprofile";
import callsvc from "../utils/Services";
import { Button } from "primereact/button";

export default class ProductModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            sideBarDialog:this.props.showCartDialog,
            selProduct:Object.assign({},this.props.selProduct),
            defQuan:1,
            addOnPrice:0,
        }
    }

    

    setSelectedSize = (itemid)=>{
        for(let i=0;i<this.state.selProduct.proditems.length;i++){
            this.state.selProduct.proditems[i].selected = (this.state.selProduct.proditems[i].itemid == itemid) ? true : false; 
        }
        this.setState({selProduct:this.state.selProduct})
    }

    
    addAddon = (it,addonid,type)=>{
        for(let k=0;k<this.state.selProduct.proditems.length;k++){
            if(this.state.selProduct.proditems[k].itemid == it.itemid){
                //it.seltotal = parseFloat(it.price) * this.state.defQuan;
                this.state.addOnPrice = 0;
                for(let i=0;i<it.addons.length;i++){
                    for(let j=0;j<it.addons[i].data.length;j++){
                        if(type=='radio' && it.addons[i].btntype == 'radio'){
                            if((it.addons[i].data[j].addonid == addonid) ){
                                it.addons[i].data[j].selected = 
                                (it.addons[i].data[j].selected) ? false :
                                true
                            }else {
                                it.addons[i].data[j].selected = false
                           
                            }
                        }else{
                            if(it.addons[i].data[j].addonid == addonid){
                                it.addons[i].data[j].selected = 
                                (it.addons[i].data[j].selected) ? false :
                                true
                            }
    
                        }
                        if(it.addons[i].data[j].selected)
                        this.state.addOnPrice = (parseFloat(this.state.addOnPrice) + parseFloat(it.addons[i].data[j].price)).toFixed(2);
                    }
                }
            }
            
        }
        
        this.setState({selProduct:this.state.selProduct,addOnPrice:this.state.addOnPrice,defQuan:1});
    }

    updateQuan=(type)=>{
        
        if(type=='add'){
            //this.calPrice(this.state.defQuan+1)
            this.setState({defQuan:this.state.defQuan+1})
        }else{
            if(this.state.defQuan <=1){
                this.setState({defQuan:1})
                this.props.hideSidebarDialog();
                return
            }
            //this.calPrice(this.state.defQuan-1)
            this.setState({defQuan:this.state.defQuan-1})

        }

    }

    callAddSvc = (obj)=>{
        callsvc(obj,'addtocart',false)
        .then((res)=>{
            console.log(res);
            Global.homeRef.getCart(userProfile.getCustObj())
            //Global.homeRef.updateProdQuan(this.state.selProduct.id,res.itemcount);
        })
    }

    

    updateCartItemQuan = (op,it)=>{
        it.ordquan = op ? parseInt(it.ordquan) + 1 : parseInt(it.ordquan) - 1;
        this.callAddSvc(it);
    }

    render(){
        
        let selected = this.state.selProduct.proditems.filter((it)=>it.selected)
        selected = selected[0];
        return(
       <Sidebar className="loc-side-bar" visible={this.props.sideBarDialog} showCloseIcon={false} onHide={() => {this.setState({sideBarDialog:false})}} style={Global.isMobile ? {backgroundColor:'transparent',height:'100vh',width:'100vh',display:'flex'}: {backgroundColor:'transparent',height:'100vh',width:'90vw'}} position={'bottom'}>
       <div style={{height:'15%',position:'relative'}}>
       <i className="pi pi-times" style={{ fontSize: '1.2rem',position:'absolute',bottom:0,left:'50%',color:'#fff',backgroundColor:'#000',padding:12,transform:'translate(-50%, -50%)' }} onClick={()=>{this.props.hideSidebarDialog()}}></i>
               
       </div>
       <div style={{backgroundColor:'#f5f5f5',height:'85%',width:'100%',borderRadius:20,overflow:'scroll',position:'relative'}}>
            
           <Card style={{backgroundColor:'#fff',borderRadius:10,padding:'0px !important'}} className="navcard">
                <div style={{display:'flex',flexDirection:'row'}}>
               <img src={this.state.selProduct.prdimg} style={{width:40,height:40,marginLeft:12}}/>
               <div style={{fontSize:'1.2rem',fontWeight:'600',marginLeft:12,alignSelf:'center'}}>{this.state.selProduct.prdname}</div>
               </div>
           </Card>
               <div style={{height:10}}></div>
           
               {this.props.showCartItems && this.state.selProduct.prdquan > 0  && <Card  style={{backgroundColor:'#fff',borderRadius:10,padding:8,margin:8}} className="navcard">
               <div style={{fontSize:'1.3rem',fontWeight:600,paddingBottom:12,borderBottom:"0.2px solid #d3d3d3"}}>Items added</div>
               
               {Global.homeRef.state.cartlist.orditlist.map((it)=>{
                   
                   if(it.prdid == this.state.selProduct.id )
                   return(
                    <div  key={it.orditid} style={{backgroundColor:'#fff',display:"flex",flexDirection:'row',justifyContent:"space-between",marginBottom:2,padding:4}}>
                        <div style={{display:'flex',flexDirection:"row",width:'100%'}}>
                        
                        <div>
                        <div style={{fontSize:'0.9rem',fontWeight:600,marginLeft:12,alignSelf:'center'}}>{it.prdname}</div>
                        <div style={{fontSize:'0.7rem',fontWeight:400,marginLeft:12,alignSelf:'center'}}>&#8377; {it.onpromo && it.promoprice? it.promoprice:it.price}</div>
                        <div style={{fontSize:'0.7rem',fontWeight:400,marginLeft:12,alignSelf:'center'}}>{it.addonstr}</div>
                        
                        <Button iconPos="right" label="Edit" icon="pi pi-angle-down" className="p-button-text" style={{color:appTheme.btnbgcolor,marginLeft:12,padding:0,fontSize:'0.8rem',fontWeight:600,maxHeight:20,lineHeight:20,textOverflow:'ellipsis'}} />
                        
                        
                       
                        </div>
                        <div style={{flex:1}}></div>
                        <div style={{alignSelf:'center',marginRight:12}}>
                        <div style={{backgroundColor:'#FFF6F7',border:`0.01em solid ${appTheme.btnbgcolor}`,borderRadius:5,display:"flex",alignItems:'center',justifyContent:'space-between',alignSelf:'center'}}>
                    <i className="pi pi-minus" style={{ fontSize: '.7rem',padding:"2px 6px",color:appTheme.btnbgcolor}} onClick={()=>{this.updateCartItemQuan(false,it)}}></i>
                    <div style={{color:'#444',fontWeight:600,fontSize:'.9rem',padding:"2px 6px",alignSelf:'center'}}>{it.ordquan}</div>
                    <i className="pi pi-plus" style={{ fontSize: '.7rem',padding:"2px 6px",color:appTheme.btnbgcolor}} onClick={()=>{this.updateCartItemQuan(true,it)}}></i>
                </div>
                <div style={{fontSize:'0.7rem',fontWeight:600,textAlign:'right'}}>&#8377; {it.orditamt}</div>
                </div>
                        </div>
                    </div>
                    )
               })}
               
           </Card>}

           
		
           <Card style={{backgroundColor:'#fff',borderRadius:10,padding:8,margin:8}} className="navcard">
               <div style={{display:'flex',justifyContent:'space-between'}} className="ml-2">
                   <div>
                   <div style={{fontSize:'1rem',textTransform:'uppercase',fontWeight:"600"}}>Choice of Size</div>
                   <div style={{fontSize:'.9rem',color:"#777777",marginTop:2}}>Select any 1 option</div>
                   </div>
                   <div style={{alignSelf:'center',backgroundColor:'transparent',color:'#D69670',border:'1px solid #D69670',borderRadius:4,fontSize:'.7rem',padding:"2px 4px"}} className="mr-2">REQUIRED</div>

               </div>
               
               {
                   this.state.selProduct.proditems.map((it)=>{
                       return(<div key={it.itemid} className="flex align-items-center" style={{padding:8,display:'flex'}}>
                       {it.selected ? <label htmlFor={it.itemid} className="ml-2" style={{fontWeight:600}}>{it.size}</label> : <label htmlFor={it.itemid} className="ml-2">{it.size}</label>}
                       
                       <div style={{flex:1}}></div>
                       {it.promo ? <div style={{marginRight:6,fontWeight:it.selected ? 600 : 400}}>&#8377;{it.promoprice}<s style={{marginLeft:5,fontSize:"0.7rem"}}>&#8377;{it.price}</s></div> : <div style={{marginRight:6,fontWeight:it.selected ? 600 : 400}}>&#8377;{it.price}</div>}
                       
                       <RadioButton inputId={it.itemid} name="size" value={it} onChange={(e) => this.setSelectedSize(e.value.itemid)} checked={it.selected} />
                       
                   </div>
           )
                   })
               }
           </Card>

           {selected.addons.map((o,index)=>{
                 if(o.data.length > 0)
                 return(<Card key={o.id} style={{backgroundColor:'#fff',borderRadius:10,padding:'8px',margin:8}} className="navcard">
                 <div style={{display:'flex',justifyContent:'space-between'}}>
                 <div style={{display:'flex',flexDirection:'column'}}>
                 <div style={{fontSize:'1rem',textTransform:"uppercase",fontWeight:600}} className="ml-2">{o.heading}</div>
                   {o.btntype == 'radio' ? <div style={{fontSize:'.9rem',color:"#777777",marginTop:2}} className="ml-2">Select any 1 option</div> : <div style={{fontSize:'.9rem',color:"#777777",marginTop:2}} className="ml-2">Select upto {o.data.length} options</div>}
                   </div>
                   {o.required ? <div style={{alignSelf:'center',backgroundColor:'transparent',color:'#D69670',border:'1px solid #D69670',borderRadius:4,fontSize:'.7rem',padding:"2px 4px"}} className="mr-2">REQUIRED</div>
                   :<div></div>}
                   </div>
                   {o.data.map((op)=>{
                       if(o.btntype == 'radio'){
                           return(<div key={op.addonid} className="flex align-items-center" style={{padding:8,display:'flex'}}>
                       {op.selected ? <label htmlFor={op.addonid} style={{fontWeight:600}} className="ml-2">{op.name}</label> :<label htmlFor={op.addonid} className="ml-2">{op.name}</label>}    
                       <div style={{flex:1}}></div>
                       <div style={{marginRight:6,fontWeight:op.selected ? 600 : 400}}>&#8377;{op.price}</div>
                      
                       <RadioButton inputId={op.addonid} name="addons" value={op} onChange={(e) => { this.addAddon(selected,op.addonid,'radio') }} checked={op.selected} />
                       
                   </div>)

                       }else{
                           return(<div key={op.addonid} className="flex align-items-center" style={{padding:8,display:'flex'}}>
                           {op.selected ? <label htmlFor={op.addonid} style={{fontWeight:600}} className="ml-2">{op.name}</label> :<label htmlFor={op.addonid} className="ml-2">{op.name}</label>}    
                           <div style={{flex:1}}></div>
                           <div style={{marginRight:6,fontWeight:op.selected ? 600 : 400}}>&#8377;{op.price}</div>
                      
                           <Checkbox onChange={(e) => { this.addAddon(selected,op.addonid,'check') }} checked={op.selected}></Checkbox>

                           </div>)

                       }
                   })}

                 </Card>)
           })}

            <div style={{height:60}}></div>

            <div style={{height:60,position:'fixed',bottom:0,left:0,backgroundColor:'#000',width:'100%',display:"flex",flexDirection:'row',justifyContent:'space-between'}}>
               <div style={{backgroundColor:'#FFF6F7',borderColor:'#FDF7F7',borderRadius:5,display:"flex",alignItems:'center',justifyContent:'space-between',padding:"8px 0px",marginLeft:8,alignSelf:'center'}}>
                   <i className="pi pi-minus" style={{ fontSize: '.76rem',padding:"6px 16px",color:'#F1415F'}} onClick={()=>{this.updateQuan('sub')}}></i>
                   <div style={{color:'#000',fontWeight:600,fontSize:'1rem',padding:"1px 0px"}}>{this.state.defQuan}</div>
                   <i className="pi pi-plus" style={{ fontSize: '.8rem',padding:"6px 16px",color:'#F1415F'}} onClick={()=>{this.updateQuan('add')}}></i>
               </div>
               <div style={{backgroundColor:appTheme.btnbgcolor,borderColor:appTheme.btnbgcolor,padding:"8px 16px",boxShadow:'0px 5px 10px 0px rgba(0, 0, 0, 0.5)',  alignSelf:'center',borderRadius:5,marginLeft:4,marginRight:12,color:'#fff'}} onClick={()=>{
                   this.sendToCart()
                   }
                   
                   }>
                   Add Item &#8377;{((parseFloat((selected.promo && selected.promoprice)? selected.promoprice:selected.price) + parseFloat(this.state.addOnPrice)) * this.state.defQuan).toFixed(2)}
               </div>

           </div>

           
           
       </div>
   </Sidebar> )
        
    }

}