import React from "react";

export default class ImageComponent extends React.Component{
    constructor(props){
        super(props);
        
    }

    render(){
        return(<img  src={this.props.imgurl} style={{width:this.props.width,height:parseInt(this.props.height)}} />)
    }
}