// import { initializeApp } from "firebase/app";
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore';

import * as  firebase from 'firebase/app';
import { getMessaging,getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import 'firebase/compat/messaging';
import Global from './utils/Global';
import callsvc from './utils/Services';
import userProfile from './utils/Userprofile';
export const initializeFirebase = () => {
    var firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyByJok0Wf-uP3FosEWkTrN7-t9G3P5q7aM",
    authDomain: "purepizza-c0868.firebaseapp.com",
    projectId: "purepizza-c0868",
    storageBucket: "purepizza-c0868.appspot.com",
    messagingSenderId: "416903021221",
    appId: "1:416903021221:web:bcac9b5a29f972f009034c",
    measurementId: "G-4E5D5J216D"
  });
}

export const askForPermissionToReceiveNotifications = async (custid) => {
  //console.log(custid);
    try {
        let app = firebase.initializeApp({
            apiKey: "AIzaSyByJok0Wf-uP3FosEWkTrN7-t9G3P5q7aM",
            authDomain: "purepizza-c0868.firebaseapp.com",
            projectId: "purepizza-c0868",
            storageBucket: "purepizza-c0868.appspot.com",
            messagingSenderId: "416903021221",
            appId: "1:416903021221:web:bcac9b5a29f972f009034c",
            measurementId: "G-4E5D5J216D"
          });
      const messaging = getMessaging(app);
      getToken(messaging, { vapidKey: 'BFd85hTa9ITI2pzKHBdjHE0vz8skjRsoZy6IjpuxxOPljSv0__G36tn5WaMYm3sQ1x4aXLTUTXvjxoEmSbUJQQg' }).then((currentToken) => {
        console.log('token called')
        if (currentToken) {
            if(Global.getNotifTokenUpdateStatus()){
              console.log('no need to updated token!');
            }else{
              //console.log('device_token ',currentToken);
              if(custid.length > 5){
                callsvc({custid:custid,device_token:currentToken},'updatedevicetoken',false)
                .then((res)=>{
                  if(res.code=='999'){
                    console.log('token updated')
                    Global.setNotifTokenUpdateStatus(true);
                  }
                })
              }
            }
        } else {
          // Show permission request UI

          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
      
    } catch (error) {
      console.error(error);
    }
  }