import React from 'react'
import {
  HashRouter as Router,
  Switch, Routes,
  Route,
  Link
} from "react-router-dom";

import 'primereact/resources/themes/lara-light-indigo/theme.css'; 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import { ProgressSpinner } from 'primereact/progressspinner';
 import Global from './utils/Global';
import './App.css';
import Launch from './pages/Launch';
import Home from './pages/Home';

class App extends React.Component{
  constructor(props){
    super(props);
    this.state={
      showSpinner:false
    }
    Global.setAppRef(this);
    
  }
  
  
    render(){
      
      return(
        <>
        {this.state.showSpinner && <div style={{position:'fixed',backgroundColor:'grey',opacity:0.3,top:0,left:0,right:0,bottom:0,zIndex:1993}}><ProgressSpinner style={{position:'absolute',top:'50%', left:'45%',width: '50px', height: '50px',zIndex:1994}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"/></div>}
        <Router basename={'/'}>
          <Switch>
          <Route  component = {Launch} />
          </Switch>
        </Router>
        </>
      )
  
  }
}

export default App;
