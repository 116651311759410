import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../css/swipercomponent.css';
import ProductCard from "./ProductCard";
import Global from "../utils/Global";
import { appTheme } from "../utils/Constants";
class CustomSwiper extends React.Component{
    
    constructor(props){
        super(props)
        this.contentSwiper = React.createRef();
        this.tabSwiper = React.createRef();
        this.state = {
            tabActive : true,
        }
    }

    changeTab=(it,index)=>{
        if(this.props.type=='cats'){
            this.props.setSelectedTab(it);
        }else{
        }
        this.contentSwiper.current.swiper.slideTo(index);
    }



    render(){
        

        let tabs = this.props.data;
        
        return(
            <div style={{marginBottom:'3rem'}} id={this.props.key}>

           {Global.isMobile ? <div style={{position:'sticky',top:55,left:0,right:0,zIndex:900}}><Swiper ref={this.tabSwiper} 
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={0}
            slidesPerView={3.5}
            onSwiper={(swiper) => console.log()}
            onSlideChange={(swiper,index) =>{}}
            className='tab-swiper'>
            {tabs.map((it,index)=>{
                return(
                    <SwiperSlide key={it.pdlid} onClick={()=>this.changeTab(it,index)} style={{height:'auto',display:'flex',justifyContent:'center',alignItems:'center',padding:"4px 12px",margin:0,color:'#333',borderBottom: it.selected ? '3px solid':'none',borderBottomColor: it.selected ? appTheme.btnbgcolor:'#fff'}}>
                    <img src={it.image} style={{width:20,height:20}} />
                    <div style={{width:10}}></div>
                    <div style={{fontSize:14,fontWeight:600}}>{it.name}</div>
                    
                    </SwiperSlide>
                )
            })}
            </Swiper> </div> :
             <div style={{position:'sticky',top:55,left:0,right:0,zIndex:900,display:'flex',justifyContent:'center',alignItems:'center',color:'#333',backgroundColor:'#fff'}}>
                {tabs.map((it,index)=>{
                    return(
                        <div key={it.pdlid} onClick={()=>this.changeTab(it,index)} style={{display:'flex',justifyContent:'center',alignItems:'center',padding:'8px',color:'#333',borderBottom: it.selected ? '3px solid':'none',borderBottomColor: it.selected ? appTheme.btnbgcolor:'#fff',width:window.innerWidth/tabs.length}}>
                        <img src={it.image} style={{width:20,height:20}} />
                        <div style={{width:10}}></div>
                        <div style={{fontSize:16,fontWeight:600}}>{it.name}</div>
                        
                        </div>
                    )
                })}
             
                </div>}

      

            <Swiper ref={this.contentSwiper} 
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            autoHeight = {true}
            onSwiper={(swiper) => console.log()}
            onSlideChange={(swiper,index) => {
                this.props.setSelectedTab(tabs[swiper.activeIndex])
                this.tabSwiper.current.swiper.slideTo(swiper.activeIndex)}
            
            }
            className='content-swiper'>
            {tabs.map((it)=>{
                return(
                    <SwiperSlide key={it.pdlid} style={{padding:'4px',margin:0}}>
                    <div  className="grid" style={{margin:12}}>{
                        it.products.map((p)=>{
                            return(
                            <ProductCard key={p.id} data={p}/> )
                        })
                        }</div>
                    </SwiperSlide>
                )
            })}
            </Swiper> 
            </div>

        )
    }
}

export default CustomSwiper;