import axios from 'axios';
import {API_URL} from  './Constants';
import userProfile from './Userprofile';
import Global from './Global';

function buildDataRequest(inpobj) {
    //console.log('buildDataRequest');
    let userObj = userProfile.getCustObj();
    let isorgid = false,isempid = false;
    var reqData = '';
    inpobj.orgid = userObj && userObj.orgid ? userObj.orgid : Global.defaultOrg;
    inpobj.custid = userObj&& userObj.custid ? userObj.custid : '';
    for (var prop in inpobj) {
        reqData = reqData + '&' + prop + "=" + inpobj[prop];
    }
       

    return reqData.substr(1);
}

function getHeaders(){
    return  { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
}

function getSecureHeaders(methodName){
    let userObj = userProfile.getCustObj();
    let returnObj = {}
    switch(methodName){
        case 'emplogin':
        case 'checksess':{
            returnObj = {'Content-Type': 'application/x-www-form-urlencoded'};
            break;
        }
        
        default: {
            returnObj = {
                'Content-Type': 'application/x-www-form-urlencoded',
                //'sessid' : userObj.sessid,
               
            };
        }
        
    }
    return returnObj
    
}




export default function callsvc(inpobj,methodName,secure=true){
    Global.showSpin(true)
    axios.defaults.headers = getSecureHeaders(methodName) 
    return new Promise((resolve,reject)=>{
        axios({
            method: 'post',
            url: API_URL+methodName,
            data: buildDataRequest(inpobj)
            //config: headers
        }).then((res)=>
        {Global.showSpin(false);resolve(res.data)})
            .catch((err)=>{Global.showSpin(false);reject(err)})
    })
}



function getUploadHeaders(){
    return  { headers: {'Content-Type': 'multipart/form-data' }}
}

export function callsvcforupload(data,methodName,secure=false){
    let headers = getUploadHeaders();
    Global.showSpin(true)
    return new Promise((resolve,reject)=>{
        axios({
            method: 'post',
            url: API_URL+methodName,
            data: data,
            config: headers
        }).then((res)=>{Global.showSpin(false);resolve(res.data)})
            .catch((err)=>{Global.showSpin(false);reject(err)})
    })
}