import React from 'react';
import {Sidebar} from 'primereact/sidebar';
import userProfile from '../utils/Userprofile';
import placeholder from  "../assets/img/placeholder.png";
import { appTheme } from '../utils/Constants';
import { Button } from 'primereact/button';
import Global from "../utils/Global";
export default class Sidemenu extends React.Component {

    constructor(props){
        super(props)
        //console.log(props)
        this.state = {
            showBar:false,
            userObj:userProfile.getCustObj(),
            navItems:[
                {scrid:1,name:'Home',icon:'pi pi-home',selected:true,scrpath:'/home'},
                {scrid:2,name:'Orders',icon:'pi pi-shopping-bag',selected:false,scrpath:'/orders'},
                {scrid:3,name:'About Us',icon:'pi pi-info-circle',selected:false,scrpath:'/about'},
                {scrid:4,name:'Contact Us',icon:'pi pi-phone',selected:false, scrpath:'/contact'},
            ]          
        }
        
    }

    navClick = (it)=>{
        let selected = null;
        for(let i=0;i<this.state.navItems.length;i++){
            if(it.scrid == this.state.navItems[i].scrid){
                selected = i;
                this.state.navItems[i].selected = true;
            }else{
                this.state.navItems[i].selected = false;
            }
        }
        

        
        this.setState({showBar:false});
        this.props.navProps.history.push(it.scrpath)
    }

    logout = ()=>{
        userProfile.removeCustObj();
        this.setState({showBar:false})
        Global.homeRef.setState({showLocDialog:true,cartCount:0});
    }

    render(){
        return (<>
         <i className="pi pi-align-justify" style={{ fontSize: 20, padding: 4,color:'#fff',marginRight:12,marginLeft:12 }} onClick={() => {this.setState({ showBar: true })}}></i>
        
        <Sidebar showCloseIcon={false} visible={this.state.showBar} onHide={() => this.setState({showBar:false})}>
         
         <div style={{display:'flex',alignItems:'center',borderBottom:'1px solid #d3d3d3',paddingBottom:12}}>
            <img src={placeholder}  style={{width:40,height:40}}/> 
            <div style={{marginLeft:12,}}>Hi {this.state.userObj && this.state.userObj.fstname ? this.state.userObj.fstname: 'Guest'}</div>
            <div style={{flex:1}}></div>
          <i className="pi pi-times p-mr-2" style={{fontSize:20}} onClick={()=>{this.setState({ showBar: false })}}></i>


         </div>

         { this.state.navItems.map((it)=>{
            
                return (<div key = {it.scrid} style={{display:'flex',alignItems:'center',borderBottom:(it.selected) ? `1px solid ${appTheme.primaryColor}`: '1px solid #d3d3d3',paddingTop:12,paddingBottom:8}} onClick={()=>{this.navClick(it)}}>
          <i className={it.icon} style={{fontSize:20}}></i>
          <div style={{paddingLeft:12}}>{it.name}</div>
          <div style={{flex:1}}></div>
          <i className="pi pi-chevron-right" style={{fontSize:16,opacity:'0.4'}}></i>

          </div>)})}
         {userProfile.getCustObj() && userProfile.getCustObj().custid && <div style={{position:'absolute',bottom:20,right:10,fontStyle:'italic',color:appTheme.btnbgcolor,padding:7, borderRadius:5,border:"1px solid black"}} onClick={()=>this.logout()}>Logout</div>}
         <div style={{height:50}}></div>
        </Sidebar>
        </>)
    }

}//end class