import React from "react";
import { appTheme } from "../utils/Constants";


export default class CartStrip extends React.Component{

    constructor(props){
        super(props)
    }

    openCart=()=>{
        //console.log(this.props)
        this.props.navProps.history.push('/cart')
    }


    render(){

        return(
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',position:'fixed',bottom:0,left:0,right:0,zIndex:99,padding:'0.6em 0px',background:'#4a4a4a',height:54}}>
                <div style={{color:'#fff',marginLeft:7}}>Cart: {this.props.cartCount > 1 ? `${this.props.cartCount} Items` : `${this.props.cartCount} Item`}</div>
                <div>
                    <button  style={{border:'1px solid',borderColor:appTheme.btnbgcolor,color:'#fff',backgroundColor:appTheme.btnbgcolor,position:'relative',overflow:'hidden',padding:9,fontSize:'0.75em',borderRadius:'3px',outline:'none',textTransform:'upppercase',marginRight:7,fontWeight:'bold',boxSizing:'border-box'}}
                    onClick={()=>this.props.cartOpen()}
                    >
                    VIEW CART
                    </button>
                </div>
            </div>

        )
    }

}