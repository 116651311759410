import React from "react";
import Global from "../utils/Global";
import { appTheme } from "../utils/Constants";
import { Sidebar } from "primereact/sidebar";
import { Card } from "primereact/card";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import userProfile from "../utils/Userprofile";
import callsvc from "../utils/Services";
import { Toast as Growl } from "primereact/toast";

export default class ProductEdit extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            sideBarDialog:true,
            selProduct:this.props.selProduct,
            defQuan:1,
            addOnPrice:0,
            orderit:null
            
        }
    }
    componentDidMount(){
        let it = this.props.orderit;

        let addonids = []
        if(it){
            if(typeof it.addons === 'string'){
                it.addons = JSON.parse(it.addons)
            }
           
            for(let i=0;i<it.addons.length;i++){
                addonids.push(it.addons[i].addonid)
            }

        }
        

        
        let addOnPrice = 0;
        //console.log(addonids,this.state.selProduct,it);
        
            let i=0;
            for( i=0; i<this.state.selProduct.proditems.length;i++){
                if(this.state.selProduct.proditems[i].selected)
                break;
            }
                for(let j=0; j<this.state.selProduct.proditems[i].addons.length;j++){
                    for(let k=0; k<this.state.selProduct.proditems[i].addons[j].data.length;k++){
                        //console.log(this.state.selProduct.proditems[i].addons[j].data[k])
                        if(addonids.includes(this.state.selProduct.proditems[i].addons[j].data[k].addonid)){
                            
                            this.state.selProduct.proditems[i].addons[j].data[k].selected = true;
                            addOnPrice+=parseInt(this.state.selProduct.proditems[i].addons[j].data[k].price)
                            
                        }else{
                            this.state.selProduct.proditems[i].addons[j].data[k].selected = false;
                        }
                        
                         
                    }
                }
            
            this.setState({selProduct: this.state.selProduct,addOnPrice:addOnPrice,defQuan: (this.props.orderit) ? parseInt(this.props.orderit.ordquan):this.state.defQuan })
            

        
    }
    sendToCart = ()=>{
        
        let selected = this.state.selProduct.proditems.filter((it)=>it.selected)
        selected = selected[0];
        //console.log(selected.addons)

        

        let req = true;
        let addonArr = [];
        
        for(let i=0;i<selected.addons.length;i++){
            for(let j=0;j<selected.addons[i].data.length;j++){
                if(selected.addons[i].data[j].selected){
                    addonArr.push(selected.addons[i].data[j])
                }
            }
        }
        if(!req){
            this.growl.show({severity:'warn',summary:'Required Fields',detail:"Please select required field.."})
            return;
        }
        
        //selected.addons.filter((it)=>it.selected);
        let sendObj = {orditid:this.props.orderit ? this.props.orderit.orditid:'',hsn:this.state.selProduct.hsn, prdid:this.state.selProduct.id, prdname:this.state.selProduct.prdname,prdimg:this.state.selProduct.prdimg ,price:selected.price, size:selected.size, onpromo:selected.promo,promoprice:selected.promoprice,
        ordquan:this.state.defQuan,prditid:selected.itemid,addons:JSON.stringify(addonArr),
        totprice:((parseFloat((selected.promo && selected.promoprice)? selected.promoprice:selected.price) + parseFloat(this.state.addOnPrice)) * this.state.defQuan).toFixed(2),
        custid:userProfile.getCustObj().custid,orgid:Global.defaultOrg
        }
        this.callAddSvc(sendObj)
        
        //console.log(sendObj);

        this.props.hideSidebarDialog()
       
    }

    callAddSvc = (obj)=>{
        callsvc(obj,'addtocart',false)
        .then((res)=>{
            console.log(res);
            Global.homeRef.getCart(userProfile.getCustObj())
            //Global.homeRef.updateProdQuan(this.state.selProduct.id,res.itemcount);
        })
    }

    setSelectedSize = (itemid)=>{
        for(let i=0;i<this.state.selProduct.proditems.length;i++){
            this.state.selProduct.proditems[i].selected = (this.state.selProduct.proditems[i].itemid == itemid) ? true : false; 
        }
        this.setState({selProduct:this.state.selProduct})
    }
    
    addAddon = (it,addonid,type)=>{
        for(let k=0;k<this.state.selProduct.proditems.length;k++){
            if(this.state.selProduct.proditems[k].itemid == it.itemid){
                //it.seltotal = parseFloat(it.price) * this.state.defQuan;
                this.state.addOnPrice = 0;
                for(let i=0;i<it.addons.length;i++){
                    for(let j=0;j<it.addons[i].data.length;j++){
                        if(type=='radio' && it.addons[i].btntype == 'radio'){
                            if((it.addons[i].data[j].addonid == addonid) ){
                                it.addons[i].data[j].selected = 
                                (it.addons[i].data[j].selected) ? false :
                                true
                            }else {
                                it.addons[i].data[j].selected = false
                           
                            }
                        }else{
                            if(it.addons[i].data[j].addonid == addonid){
                                it.addons[i].data[j].selected = 
                                (it.addons[i].data[j].selected) ? false :
                                true
                            }
    
                        }
                        if(it.addons[i].data[j].selected)
                        this.state.addOnPrice = (parseFloat(this.state.addOnPrice) + parseFloat(it.addons[i].data[j].price)).toFixed(2);
                    }
                }
            }
            
        }
        
        this.setState({selProduct:this.state.selProduct,addOnPrice:this.state.addOnPrice,defQuan:1});
    }

    updateQuan=(type)=>{
        
        if(type=='add'){
            //this.calPrice(this.state.defQuan+1)
            this.setState({defQuan:this.state.defQuan+1})
        }else{
            if(this.state.defQuan <=1){
                this.setState({defQuan:1})
                this.props.hideSidebarDialog();
                return
            }
            //this.calPrice(this.state.defQuan-1)
            this.setState({defQuan:this.state.defQuan-1})

        }

    }
    
    render(){
        
        let selected = this.state.selProduct.proditems.filter((it)=>it.selected)
        selected = selected[0];
       
        
    return(
        <Sidebar className="loc-side-bar" visible={this.state.sideBarDialog} showCloseIcon={false} onHide={() => {this.props.hideSidebarDialog()}} style={Global.isMobile ? {backgroundColor:'transparent',height:'100vh',width:'100vh',display:'flex'}: {backgroundColor:'transparent',height:'100vh',width:'90vw'}} position={'bottom'}>
        <Growl ref={(el) => this.growl = el} style={{width:"70vw"}} sticky={"true"} position="top-right" />
        <div style={{height:'15%',position:'relative'}}>
            <i className="pi pi-times" style={{ fontSize: '1rem',position:'absolute',bottom:0,left:'50%',color:'#fff',backgroundColor:'#000',padding:12,transform:'translate(-50%, -50%)',borderRadius:20 }} onClick={()=>{this.props.hideSidebarDialog()}}></i>
        </div>
        <div style={{position:'relative',backgroundColor:'#FAFAFA',height:'85%',width:'100%',borderRadius:20,overflow:'scroll',position:'relative'}}>
        <Card style={{backgroundColor:'#fff',borderRadius:10,padding:'0px !important'}} className="navcard">
            <div style={{display:'flex',flexDirection:'row'}}>
                <img src={this.state.selProduct.prdimg} style={{width:40,height:40,marginLeft:12}}/>
                <div style={{fontSize:'1.2rem',fontWeight:'600',marginLeft:12,alignSelf:'center'}}>{this.state.selProduct.prdname}</div>
            </div>
        </Card>
        <div style={{height:10}}></div>



 
<Card style={{backgroundColor:'#fff',borderRadius:10,padding:8,margin:8}} className="navcard">
   <div style={{display:'flex',justifyContent:'space-between'}} className="ml-2">
       <div>
       <div style={{fontSize:'1rem',textTransform:'uppercase',fontWeight:"600"}}>Choice of Size</div>
       <div style={{fontSize:'.9rem',color:"#777777",marginTop:2}}>Select any 1 option</div>
       </div>
       <div style={{alignSelf:'center',backgroundColor:'transparent',color:'#D69670',border:'1px solid #D69670',borderRadius:4,fontSize:'.7rem',padding:"2px 4px"}} className="mr-2">REQUIRED</div>

   </div>
   
   {
       this.state.selProduct.proditems.map((it)=>{
           return(<div key={it.itemid} className="flex align-items-center" style={{padding:8,display:'flex'}}>
           {it.selected ? <label htmlFor={it.itemid} className="ml-2" style={{fontWeight:600}}>{it.size}</label> : <label htmlFor={it.itemid} className="ml-2">{it.size}</label>}
           
           <div style={{flex:1}}></div>
           {it.promo ? <div style={{marginRight:6,fontWeight:it.selected ? 600 : 400}}>&#8377;{it.promoprice}<s style={{marginLeft:5,fontSize:"0.7rem"}}>&#8377;{it.price}</s></div> : <div style={{marginRight:6,fontWeight:it.selected ? 600 : 400}}>&#8377;{it.price}</div>}
           
           <RadioButton inputId={it.itemid} name="size" value={it} onChange={(e) => this.setSelectedSize(e.value.itemid)} checked={it.selected} />
           
       </div>
)
       })
   }
</Card>

{selected.addons.map((o,index)=>{
     if(o.data.length > 0)
     return(<Card key={o.id} style={{backgroundColor:'#fff',borderRadius:10,padding:'8px',margin:8}} className="navcard">
     <div style={{display:'flex',justifyContent:'space-between'}}>
     <div style={{display:'flex',flexDirection:'column'}}>
     <div style={{fontSize:'1rem',textTransform:"uppercase",fontWeight:600}} className="ml-2">{o.heading}</div>
       {o.btntype == 'radio' ? <div style={{fontSize:'.9rem',color:"#777777",marginTop:2}} className="ml-2">Select any 1 option</div> : <div style={{fontSize:'.9rem',color:"#777777",marginTop:2}} className="ml-2">Select upto {o.data.length} options</div>}
       </div>
       {o.required ? <div style={{alignSelf:'center',backgroundColor:'transparent',color:'#D69670',border:'1px solid #D69670',borderRadius:4,fontSize:'.7rem',padding:"2px 4px"}} className="mr-2">REQUIRED</div>
       :<div></div>}
       </div>
       {o.data.map((op)=>{
           if(o.btntype == 'radio'){
               return(<div key={op.addonid} className="flex align-items-center" style={{padding:8,display:'flex'}} onClick={(e) => { this.addAddon(selected,op.addonid,'radio') }}>
           {op.selected ? <label htmlFor={op.addonid} style={{fontWeight:600}} className="ml-2">{op.name}</label> :<label htmlFor={op.addonid} className="ml-2">{op.name}</label>}    
           <div style={{flex:1}}></div>
           <div style={{marginRight:6,fontWeight:op.selected ? 600 : 400}}>&#8377;{op.price}</div>
          
           <RadioButton inputId={op.addonid} name="addons" value={op}  checked={op.selected} />
           
       </div>)

           }else{
               return(<div key={op.addonid} className="flex align-items-center" 
               onClick={(e) => { this.addAddon(selected,op.addonid,'check') }}
               style={{padding:8,display:'flex'}}>
               {op.selected ? <label htmlFor={op.addonid} style={{fontWeight:600}} className="ml-2">{op.name}</label> :<label htmlFor={op.addonid} className="ml-2">{op.name}</label>}    
               <div style={{flex:1}}></div>
               <div style={{marginRight:6,fontWeight:op.selected ? 600 : 400}}>&#8377;{op.price}</div>
          
               <Checkbox  checked={op.selected}></Checkbox>

               </div>)

           }
       })}

     </Card>)
})}

<div style={{height:60}}></div>

<div style={{height:60,position:'sticky',bottom:0,left:0,backgroundColor:'#000',width:'100%',display:"flex",flexDirection:'row',justifyContent:'space-between'}}>
   <div style={{backgroundColor:'#FFF6F7',borderColor:'#FDF7F7',borderRadius:5,display:"flex",alignItems:'center',justifyContent:'space-between',padding:"8px 0px",marginLeft:8,alignSelf:'center'}}>
       <i className="pi pi-minus" style={{ fontSize: '.76rem',padding:"6px 16px",color:'#F1415F'}} onClick={()=>{this.updateQuan('sub')}}></i>
       <div style={{color:'#000',fontWeight:600,fontSize:'1rem',padding:"1px 0px"}}>{this.state.defQuan}</div>
       <i className="pi pi-plus" style={{ fontSize: '.8rem',padding:"6px 16px",color:'#F1415F'}} onClick={()=>{this.updateQuan('add')}}></i>
   </div>
   <div style={{backgroundColor:appTheme.btnbgcolor,borderColor:appTheme.btnbgcolor,padding:"8px 16px",boxShadow:'0px 5px 10px 0px rgba(0, 0, 0, 0.5)',  alignSelf:'center',borderRadius:5,marginLeft:4,marginRight:12,color:'#fff'}} onClick={()=>{
       this.sendToCart()
       }
       
       }>
       Add Item &#8377;{((parseFloat((selected.promo && selected.promoprice)? selected.promoprice:selected.price) + parseFloat(this.state.addOnPrice)) * this.state.defQuan).toFixed(2)}
   </div>

</div>



 </div>
</Sidebar>
    )// end render

    }

}