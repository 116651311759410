
export const API_URL = 'https://bakedngrilled.in/appapi/index.php/';
export const APP_ID = 'BNG';
export const APP_URL = 'https://bakedngrilled.in/#/';

export const APP_VERSION = 2;

export const GLOBAL_ERROR = 'Oops.. something seems wrong,Please try again / contact your administrator';
export const ADMIN_ERROR = 'something seems to be wrong, Please contact your administrator';
export const NO_ROWS = 'No Rows created as of now. Please create!';
export const REQ_FIELDS = ' Oops _FIELDS_ seems to be missing !, Please check';
  
export const __DEV__ = true; 

export const appTheme = {
  
    primaryColor: '#689f39', //'#E16605', 
    textColor:'#fff',
    lightTextColor:'#454545',
    secondaryColor:'#fff', //#EB3875
    launchBgColor:'#361059',
    launchTextColor:'#fff',
    backgroundColor: '#f9f9f9', //'#ECECEC',
    btnbgcolor:'#f64c3d',
    stripBgColor:'rgb(74, 74, 74)',
    pageBgColor:'#F5F5F5',
    ribbon: {
        backgroundColor:'#f64c3d',
        width: '85%',
        height: 0,
        borderTopColor: '#f64c3d',
        borderLeftColor: '#f64c3d',
        borderRightColor: 'transparent',
        borderBottomColor: '#f64c3d',
        borderWidth: 12,
        justifyContent: 'center',
        top: 4,
        zIndex: 22,
        left: 0,
      }


}

export const styleDrag = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    margin: 30
}




//'http://groceriesplay.marvij.com/' 'https://meebazaar.com/adminapi/index.php/'','https://meebazaar.com/app/index.php/';
//35.154.11.171;