import React from "react";
import Catwithprods from './Catwithprods';
import { Card } from 'primereact/card';
import CustomSwiper from "./Swiper";
export default class CategoryGrid extends React.Component{
    constructor(props){
        super(props);
        
    }

    render(){


        return(<div >
            <div style={{backgroundColor:'#f9f9f9',margin:0,padding:12,color:'#333',fontSize:18,fontWeight:600}}>Please choose from</div>
            <CustomSwiper data={this.props.data.items} type='cats' setSelectedTab={this.props.setSelectedTab}/>
        </div>)
    }
}